<template>
    <el-dialog
        :visible.sync="show"
        width="280px"
        :close-icon="''"
        class="project-modal"
    >
        <div>
            <div class="text-center mb-3 me-2">{{ text }}</div>

            <div class="d-flex justify-content-center">
                <el-button :loading="loading" @click="success" type="success">Yes</el-button>
                <el-button :loading="loading" @click="close">No</el-button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: "StopProjectModal",
    props: {
        show: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        text: {
            type: String,
            default: "Are You Sure to Stop the Project and Terminate Collection of Documents?"
        }
    },
    methods: {
        close() {
            this.$emit("close");
        },
        success() {
          this.$emit("success");
        }
    }
}
</script>