<template>
  <el-dialog
      :visible.sync="show"
      width="480px"
      :close-icon="''"
      class="project-modal"
  >
    <div>
      <div class="text-center mb-3 me-2"><h3>{{ heading }}</h3></div>
      <div class="text-center mb-3 me-2">{{ text }}</div>

      <div class="d-flex justify-content-center">
        <el-button :loading="loading" @click="deleteTeam" type="danger">Delete</el-button>
        <el-button :loading="loading" @click="downloadDocs" type="success">Download Docs</el-button>
        <el-button :loading="loading" @click="close">Cancel</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "DeleteTeamModal",
  props: {
    show: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    heading: {
      type: String,
    },
    text: {
      type: String,
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    deleteTeam() {
      this.$emit("deleteTeam");
    },
    downloadDocs() {
      this.$emit("downloadDocs");
    }
  }
}
</script>