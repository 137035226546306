<template>
    <div>
        <!--<div class="clearfix">
            <h4 class="sidebar-title mt-3 ms-3 float-start">{{ title }} Projects: Upload Document</h4>
        </div>-->
        <div v-if="!isCollaborator" class="d-flex align-items-center justify-content-between mb-3">
            <h3 @click="$emit('close')" style="cursor:pointer;" class="card-title mb-3"><i class="fa fa-angle-left"></i>
                {{ project.title }}</h3>
            <el-tag v-if="project.pid !== null && !(project.project_type == 'PRIVATE')">Project Id: {{ project.pid }}</el-tag>
        </div>
        <div v-if="attachments.length > 0 && !isCollaborator">
            <p class="fw-bold mb-1">Shared Documents For Review </p>
            <ul class="list project-doc-list">
                <li v-for="doc in attachments" :key="doc.id">
                    <el-button
                            @click.prevent="attachmentDownload(doc)"
                            type="text"> {{doc.original_name }}
                    </el-button>
                </li>
            </ul>
        </div>
        <p class="fw-bold mb-1 mt-3">List Required Documents</p>
        <div class="table-responsive">
            <table class="table custom-table border">
                <tr>
                    <th>File Name</th>
                    <th>Max Size</th>
                    <th>Format</th>
                </tr>
                <tr v-for="file in files">
                    <td>
                        <span v-if="file.template === null">{{ file.original_name ? file.original_name : file.title }}</span>
                        <el-button
                                v-else
                                @click.prevent="download(file)"
                                type="text">{{ file.original_name ? file.original_name : file.title }}
                        </el-button>
                    </td>
                    <td>{{ file.max_size }} {{ file.size_unit }}</td>
                    <td>
                        <el-select
                                v-if="file.formats"
                                v-model="file.formats.split(',')"
                                placeholder="Select"
                                multiple
                                disabled
                        >
                            <el-option
                                    v-for="(item, index) in fileTypes"
                                    :key="index"
                                    :label="item.label"
                                    :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </td>
                </tr>
                <tr v-if="files.length == 0">
                    <td colspan="3">
                        <div class="text-danger text-center">Needed list required documents.</div>
                    </td>
                </tr>
            </table>
        </div>
        <div style="display: flex;justify-content: space-between">
            <p class="fw-bold mb-1">Files form Collaborators</p>
            <div class="pb-2">
                <el-button size="small" @click="downloadDocs('APPROVED')" :loading="downloadApproved" round>Download Approved</el-button>
                <el-button size="small" @click="downloadDocs()" :loading="downloadAll" round>Download All</el-button>
            </div>
        </div>
        <div class="table-responsive">
            <table class="table custom-table border m-0">
                <thead>
                <tr>
                    <th>Collaborator</th>
                    <th>Status</th>
                    <th>Submission</th>
                    <th>Approval</th>
                    <th>Documents</th>
                </tr>
                </thead>
                <tbody v-if="collaborators.length > 0" v-for="user in collaborators" :key="user.id">
                <tr>
                    <td>{{ user.first_name + ' ' + user.last_name }}</td>
                    <td>{{
                        (user.total_approved_documents && user.total_approved_documents === user.total_assigned_documents) ? 'Completed' : 'Pending'
                        }}
                    </td>
                    <td>
                        <el-button round>
                            {{ user.total_submitted_documents }}/{{ user.total_assigned_documents }}
                        </el-button>
                    </td>
                    <td>
                        <el-button round>
                            {{ user.total_approved_documents }}/{{ user.total_submitted_documents }}
                        </el-button>
                    </td>
                    <td>
                        <el-button
                                @click.prevent="getDocuments(user)"
                                icon="el-icon-arrow-down"
                                circle
                                plain
                        />
                    </td>
                </tr>
                <tr v-if="user.showDocument">
                    <td class="p-0" colspan="6">
                        <!--                    <h5>Assigned Documents</h5>-->
                        <!--                    <table class="table table-borderless table-responsive-md">-->
                        <!--                        <thead>-->
                        <!--                        <tr>-->
                        <!--                            <th>File Name</th>-->
                        <!--                            <th>Max Size</th>-->
                        <!--                            <th>Format</th>-->
                        <!--                        </tr>-->
                        <!--                        </thead>-->

                        <!--                        <tbody>-->
                        <!--                        <tr v-for="document in user.documents" :key="document.id">-->
                        <!--                            <td>-->
                        <!--                                <el-button-->
                        <!--                                    v-if="document.submitFile"-->
                        <!--                                    type="text"-->
                        <!--                                    @click.prevent="download(document.submitFileId)"-->
                        <!--                                >-->
                        <!--                                    {{ document.title }}-->
                        <!--                                </el-button>-->
                        <!--                                <div v-else>{{ document.title }}</div>-->
                        <!--                            </td>-->
                        <!--                            <td>{{ document.max_size }} {{ document.size_unit }}</td>-->
                        <!--                            <td>-->
                        <!--                                <el-select-->
                        <!--                                    style="width: 100%; max-width: 300px;"-->
                        <!--                                    v-model="document.formats.split(',')"-->
                        <!--                                    placeholder="Select"-->
                        <!--                                    multiple-->
                        <!--                                    disabled-->
                        <!--                                >-->
                        <!--                                    <el-option-->
                        <!--                                        v-for="(item, index) in fileTypes"-->
                        <!--                                        :key="index"-->
                        <!--                                        :label="item.label"-->
                        <!--                                        :value="item.value"-->
                        <!--                                    >-->
                        <!--                                    </el-option>-->
                        <!--                                </el-select>-->
                        <!--                            </td>-->
                        <!--                        </tr>-->
                        <!--                        </tbody>-->
                        <!--                    </table>-->
                        <!--                    <h5>Submit Documents</h5>-->
                        <el-form :rules="rules">
                            <table class="document-table table custom-table border m-0">
                                <thead>
                                <tr>
                                    <th>Document Name</th>
                                    <th>Approve Status</th>
                                    <th>Note</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="document in user.submitDocuments" :key="document.id">
                                    <td>
                                        <el-button
                                                type="text"
                                                @click.prevent="download(document)"
                                        >
                                            {{ document.title }}
                                        </el-button>
                                    </td>
                                    <td>
                                      <div  v-if="project.status =='DELETED'">
                                        {{ document.host_status }}
                                      </div>
                                      <el-form-item :error="document.host_statusError" prop="document.host_status" v-else>
                                            <el-select
                                                    v-model="document.host_status"
                                                    placeholder=""
                                                    style="width: 130px;">
                                                <el-option
                                                        v-for="item in ['APPROVED', 'REJECTED']"
                                                        :key="item"
                                                        :label="item"
                                                        :value="item"
                                                />
                                            </el-select>
                                        </el-form-item>
                                    </td>
                                    <td>
                                      <div  v-if="project.status =='DELETED'">
                                        {{ document.host_comment }}
                                      </div>
                                      <div v-else>
                                        <el-form-item :error="document.host_commentError" prop="document.host_comment">
                                            <el-input v-model="document.host_comment" placeholder="Note"/>
                                        </el-form-item>
                                      </div>
                                    </td>
                                </tr>
                                <tr v-if="user.submitDocuments.length == 0">
                                    <td colspan="3">
                                        <div class="text-danger text-center">Needed Files from
                                            {{ user.first_name + ' ' + user.last_name }}.
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                                <tfoot>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <div class="d-flex justify-content-end" v-if="user.submitDocuments.length > 0">
                                            <el-button
                                                    type="success"
                                                    plain
                                                    :loading = "downloadingUserZip"
                                                    @click="downloadDocuments(user)"
                                            >
                                                Download All
                                            </el-button>
                                            <el-button  v-if="project.status!=='DELETED'"
                                                    type="success"
                                                    plain
                                                    @click="sendDesicion(user)"
                                            >
                                                Save Decisions
                                            </el-button>
                                        </div>
                                    </td>
                                </tr>
                                </tfoot>
                            </table>
                        </el-form>
                    </td>
                </tr>
                </tbody>
                <tbody v-if="collaborators.length <= 0">
                <tr>
                    <td colspan="6">
                        <div class="text-danger text-center">Needed Files from Collaborators.</div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <stop-project-modal
                :show="showSendEmailModal"
                :text="`Send email to the Collaborator ?`"
                @close="showSendEmailModal = false"
                @success="sendMail"
        />
    </div>
</template>

<script>
import axios from "axios";
import {Notification} from "element-ui";
import StopProjectModal from "../StopProjectModal.vue";

export default {
    name: "ProjectDetails",
    components: {StopProjectModal},
    props: {
      project: {
        type: Object,
      },
      title: {
        type: String,
      },
      isCollaborator: {
        type: Boolean,
        default: false
      },
      documentId: {
        default: ""
      }
    },
    watch: {
      documentId(val) {
        console.log(val, "adfa")
        if (val) {
          this.getProjectDetails();
        }
      }
    },
    data() {
        return {
            collaborators: [],
            files: [],
            attachments: [],
          fileTypes: [
            {
              value: "image/jpeg",
              label: "JPEG"
            },
            {
              value: "image/png",
              label: "PNG"
            },
            {
              value: "application/pdf",
              label: "PDF"
            },
            {
              value: "application/doc",
              label: "MS Word"
            },
            {
              value: "application/ms-doc",
              label: "MS Word (.docx)"
            },
            {
              value: "application/msword",
              label: "MS Word"
            },
            {
              value: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              label: "MS Word (OpenXML)"
            },
            {
              value: "application/vnd.ms-excel",
              label: "MS Excel"
            },
            {
              value: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              label: "MS Excel (OpenXML)"
            },
            {
              value: "application/vnd.ms-powerpoint",
              label: "MS PowerPoint"
            },
            {
              value: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
              label: "MS PowerPoint (OpenXML)"
            },
            {
              value: "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
              label: "MS PowerPoint (OpenXML)"
            }
          ],
            rules: {},
            showSendEmailModal: false,
            tempUser: {},
            foundError: false,
            downloadingUserZip: false,
            downloadApproved:false,
            downloadAll: false,
        }
    },
    mounted() {
        this.getProjectDetails();
        this.getProjectFilesDetails()
    },
    methods: {
        getProjectFilesDetails() {
            axios.get(`projects/show/${this.project.id}`)
                .then(response => {
                    this.files = response.data.documents;
                    this.attachments = response.data.attachments;
                })
                .catch(error => {
                    console.log(error);
                })
        },
        getProjectDetails() {
            axios.get('/projects/show/' + this.project.id)
                .then((res) => {
                  console.log(res);
                  this.collaborators = [];
                    res.data.collaborators.forEach(collaborator => {
                        collaborator.showDocument = false;
                        collaborator.submitDocuments = [];
                        collaborator.location = false;
                        this.collaborators.push(collaborator);
                    });
                })
                .catch((error) => {
                    if (error.response.data.errors) {
                        Notification.error(Object.values(error.response.data.errors)[0][0]);
                    } else {
                        Notification.error(error.response.data.message);
                    }
                })
        },
      downloadDocs(type = null) {

        let endPoint = `/download/project-documents/${this.project.id}`;
        if (type) {
          endPoint = endPoint + `?filter=${type}`;
        }
        if(type == "APPROVED"){
          this.downloadApproved = true;
        }else{
          this.downloadAll = true;
        }
        axios
            .get(endPoint, {responseType: 'blob'})
            .then(response => {
            // console.log(response);
              const contentType = response.headers['content-type'];
              if (contentType === 'application/json') {
                  Notification.error("Nothing to download");
                return response.data.text(); // Read the response data as text
              } else {
                return response; // Continue with the blob response
              }
            })
            .then(response => {
              if (typeof response === 'string') {
                const responseData = JSON.parse(response);
                if (responseData.message) {
                  // File count is 0, display the message or perform the desired action
                  Notification.error(responseData.message)
                }
              } else {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${this.project.title} ${type != null ? type : 'All'}.zip`);
                document.body.appendChild(link);
                link.click();
              }
            })
            .catch(error => {
              Notification.error(error.response.data.error);
              console.log(error);
            })
            .finally(() => {
              if(type == "APPROVED"){
          this.downloadApproved = false;
        }else{
          this.downloadAll = false;
        }
            });
      },
        sendEmailDecisions(collaborator) {
            this.$store
                .dispatch("projectLists/sendEmailDecisions", {
                    projectId: this.project.id,
                    collaboratorId: collaborator.id
                }).then((response) => {
                Notification.success(response.data.message);
            }).catch((error) => {
                if (error.response.data.errors) {
                    Notification.error(Object.values(error.response.data.errors)[0][0]);
                } else {
                    Notification.error(error.response.data.message);
                }
            })
        },

        sendDesicion(user) {
            this.foundError = false;
            user.submitDocuments.forEach((doc, index) => {
                console.log('doc', user.submitDocuments[index].host_comment)
                this.validationCheck('host_comment', user, index);
                this.validationCheck('host_status', user, index);
            })
            if (!this.foundError) {
                user.submitDocuments.forEach((doc) => {
                    doc.user_id = user.id;
                })
                this.saveDecision(user.submitDocuments);
                this.tempUser = user;
            }
        },
        validationCheck(property, user, index) {
            let document = user.submitDocuments[index];
            if (!document[property] || document[property] === '') {
                this.foundError = true;
                this.$forceUpdate();
                document[property + 'Error'] = 'This field is required';
            } else {
                document[property + 'Error'] = '';
            }
        },
        saveDecision(documents) {
            axios.post('/host-update-files-comments', {
                docs: documents
            })
                .then(() => {
                    this.$store
                        .dispatch("privateProjects/saveDecision", {
                            data: {
                              docs: documents
                            },
                            projectId: this.project.id,
                        })
                        .then((response) => {
                          $('.el-notification').remove();
                          if(response.data.status == 'success'){
                              Notification.success(response.data.message);
                              this.$emit('saved')
                              this.getProjectDetails();
                              this.$forceUpdate();
                              this.showSendEmailModal = true;
                            }
                            else {
                                Notification.error(response.data.message);
                            }
                        }).catch((error) => {
                        if (error.response.data.errors) {
                            $('.el-notification').remove()
                            Notification.error(Object.values(error.response.data.errors)[0][0]);
                        } else {
                            $('.el-notification').remove()
                            Notification.error(error.response.data.message);
                        }
                    });
                })

        },

        getDocuments(user) {
            let rootIndex = this.collaborators.indexOf(user);
            this.collaborators[rootIndex].showDocument = !this.collaborators[rootIndex].showDocument;

            axios.post('/projects/project-collaborator/files', {
                project_id: this.project.id,
                collaborator_id: user.id
            }).then((res) => {
                this.collaborators[rootIndex].submitDocuments = res.data;
            });
        },

      downloadDocuments(user) {
        this.downloadingUserZip = true;
        let endPoint = `/download/documents-all/${this.project.id}/${user.id}`;
        axios.get(endPoint, {responseType:'blob'})
            .then(response => {
              console.log(user);
              // console.log(response);return;
              const contentType = response.headers['content-type'];
              if (contentType === 'application/json') {
                return response.data.text(); // Read the response data as text
              } else {
                return response; // Continue with the blob response
              }
            })
            .then(response => {
              if (typeof response === 'string') {
                const responseData = JSON.parse(response);
                if (responseData.message) {
                  // File count is 0, display the message or perform the desired action
                  Notification.error(responseData.message)
                }
              } else {

                // console.log(response); return;
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${user.first_name}'s Documents of ${this.project.title}.zip`);
                document.body.appendChild(link);
                link.click();
              }
            })
            .catch(error => {
              console.log(error);
            })
            .finally(() => {
              this.downloadingUserZip = false;
            })
            ;
      },

        attachmentDownload(file) {
          console.log(file);
          axios.get(`download/attachment/${file.id}`, { responseType: 'blob' })
              .then(response => {
                this.downloadFile(response, file);
              }).catch(err => {
            Notification.error('Requested file is missing!');
          })
        },
        download(file) {
          axios.get(`download/file/${file.id}`, { responseType: 'blob' })
              .then(response => {
                this.downloadFile(response, file)
              }).catch(err => {
            // console.log(err)
            Notification.error('Requested file is missing!');

            // Notification.error(err.response.data.error + ' or The file is missing');
          })
        },
        downloadFile(response, file){
          const blob = new Blob([response.data], {type: response.headers['content-type']});
          const url = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = file.original_name;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);  
        },
        sendMail() {
            this.sendEmailDecisions(this.tempUser);
            this.showSendEmailModal = false;
            this.$forceUpdate();
        }
    },

}
</script>

<style scoped>
.el-form-item {
    margin-bottom: 0;
}

.selectOption {
    width: 130px !important;
    height: 50px !important;
    border: 1px solid #000000 !important;
    border-radius: 50% !important;
}

</style>