<template>
  <div class="project-detail mt-3 py-3">
    <div v-if="!showDetails">
      <h3 class="card-title mb-3">All {{ projectsStatus }} Projects</h3>
      <div class="table-responsive">
        <table class="table custom-table mb-0">              
          <thead>
          <tr>
            <th>Type</th>
            <th>Project Name</th>
            <th>Start Date</th>
            <th>Deadline</th>
            <th>Your Role</th>
            <th>Collaborators</th>
            <th>Docs Collected</th>
            <th>Docs Approved</th>
            <th>Actions</th>
          </tr>
          </thead>
          <tbody class="position-relative">
            <div v-if="firstLoading" class="w-100 h-100 position-absolute bg-black d-flex align-items-center justify-content-center bg-white" style="z-index: 10000;">
              <i style="font-size: 25px" class="el-icon-loading text-black"></i>
            </div>
          <tr v-if="projects.length > 0" v-for="project in projects" :key="project.id">
            <td v-if="project.project_type == 'PUBLIC'">
              <svg xmlns="http://www.w3.org/2000/svg" width="16"
                   height="21"
                   viewBox="0 0 16 21">
                <g id="lock-open" transform="translate(-4 -1)">
                  <rect id="Rectangle_2" data-name="Rectangle 2" width="14"
                        height="10" rx="2"
                        transform="translate(5 11)" fill="none" stroke="#2f80ed"
                        stroke-linecap="round" stroke-linejoin="round"
                        stroke-width="2"/>
                  <circle id="Ellipse_13" data-name="Ellipse 13" cx="1" cy="1"
                          r="1"
                          transform="translate(11 15)" fill="none"
                          stroke="#2f80ed"
                          stroke-linecap="round" stroke-linejoin="round"
                          stroke-width="2"/>
                  <path id="Path_3" data-name="Path 3" d="M8,11V6a4,4,0,0,1,8,0"
                        fill="none"
                        stroke="#2f80ed" stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"/>
                </g>
              </svg>
            </td>
            <td v-else>
              <svg xmlns="http://www.w3.org/2000/svg" width="15.647" height="19"
                   viewBox="0 0 15.647 19">
                <path id="git-repository-private-line"
                      d="M5.608,9.143V18.19h11.3V9.143Zm10.431-1.81h1.739a.888.888,0,0,1,.869.9V19.1a.888.888,0,0,1-.869.9H3.869A.888.888,0,0,1,3,19.1V8.238a.888.888,0,0,1,.869-.9H5.608v-.9A5.325,5.325,0,0,1,10.824,1a5.325,5.325,0,0,1,5.216,5.429Zm-1.739,0v-.9A3.55,3.55,0,0,0,10.824,2.81,3.55,3.55,0,0,0,7.346,6.429v.9ZM6.477,10.048H8.216v1.81H6.477Zm0,2.714H8.216v1.81H6.477Zm0,2.714H8.216v1.81H6.477Z"
                      transform="translate(-3 -1)" fill="#2f80ed"/>
              </svg>
            </td>
            <td>{{ project.title }}</td>
            <td>
              <div class="white-space">{{ project.start_date }}</div>
            </td>
            <td>
              <div class="white-space">{{ project.end_date_first }}</div>
            </td>
            <td>
              <span class="badge rounded-pill bg-success">
                  {{ getUserType(project.my_roles) }}
              </span>
            </td>
            <td>{{ project.total_collaborators }}</td>
            <td>{{ project.collected_documents }}</td>
            <td>{{ project.approved_documents }}</td>
            <td>
              <div class="d-flex align-items-center">
                <el-tooltip class="item" effect="dark" content="View Details" placement="top">
                  <button style="border: none; background: none" @click.prevent="viewDetails(project)"
                          class="me-2">
                    <svg id="Group_47" data-name="Group 47"
                         xmlns="http://www.w3.org/2000/svg" width="31" height="31"
                         viewBox="0 0 31 31">
                      <g id="eye-fill" transform="translate(10 12)">
                        <path id="Path_1" data-name="Path 1"
                              d="M9.016,7.258A1.758,1.758,0,1,1,7.258,5.5,1.758,1.758,0,0,1,9.016,7.258Z"
                              transform="translate(-1.633 -3.391)" fill="#00b754"/>
                        <path id="Path_2" data-name="Path 2"
                              d="M0,6.367S2.109,2.5,5.625,2.5,11.25,6.367,11.25,6.367s-2.109,3.867-5.625,3.867S0,6.367,0,6.367ZM5.625,8.828A2.461,2.461,0,1,0,3.164,6.367,2.461,2.461,0,0,0,5.625,8.828Z"
                              transform="translate(0 -2.5)" fill="#00b754"
                              fill-rule="evenodd"/>
                      </g>
                      <circle id="Ellipse_62" data-name="Ellipse 62" cx="15.5"
                              cy="15.5" r="15.5" fill="#00b754" opacity="0.1"/>
                    </svg>
                  </button>
                </el-tooltip>

                <el-tooltip class="item" effect="dark" content="Edit" placement="top" v-if="project.status !== 'PAST'">
                  <button v-if="showEditBtn(project)" style="border: none; background: none"
                          @click.prevent="editProject(project)" class="me-2">
                    <svg id="Group_48" data-name="Group 48"
                         xmlns="http://www.w3.org/2000/svg" width="31" height="31"
                         viewBox="0 0 31 31">
                      <circle id="Ellipse_62" data-name="Ellipse 62" cx="15.5"
                              cy="15.5" r="15.5" fill="#00b754" opacity="0.1"/>
                      <g id="Edit" transform="translate(10.075 10.075)">
                        <path id="Edit-2" data-name="Edit"
                              d="M6.8,10.851a.617.617,0,0,1,0-1.234h3.442a.617.617,0,0,1,0,1.234ZM.511,10.595l-.48-2.08A1.285,1.285,0,0,1,.275,7.432L4.029,2.573a.188.188,0,0,1,.256-.032L5.865,3.8a.509.509,0,0,0,.39.111.569.569,0,0,0,.492-.629.63.63,0,0,0-.2-.382L5.014,1.666a.228.228,0,0,1-.038-.317L5.571.577A1.557,1.557,0,0,1,7.854.421l.889.707a1.845,1.845,0,0,1,.69,1.057A1.446,1.446,0,0,1,9.14,3.416l-5.3,6.849a1.269,1.269,0,0,1-.985.492l-2.11.026A.241.241,0,0,1,.511,10.595Z"
                              fill="#00b754"/>
                      </g>
                    </svg>
                  </button>
                </el-tooltip>

                <el-tooltip class="item" effect="dark" content="Duplicate" placement="top">
                  <button v-if="showCopyBtn(project)" style="border: none; background: none"
                          @click.prevent="copyProject(project)" class="me-2">
                    <svg id="Group_50" data-name="Group 50"
                         xmlns="http://www.w3.org/2000/svg" width="31" height="31"
                         viewBox="0 0 31 31">
                      <circle id="Ellipse_61" data-name="Ellipse 61" cx="15.5"
                              cy="15.5" r="15.5" fill="#eb5757" opacity="0.1"/>
                      <path id="copy-24"
                            d="M3.972,3A.972.972,0,0,0,3,3.972V9.25a.973.973,0,0,0,.972.972h.833a.417.417,0,1,0,0-.833H3.972a.139.139,0,0,1-.139-.139V3.972a.139.139,0,0,1,.139-.139H9.25a.139.139,0,0,1,.139.139v.833a.417.417,0,1,0,.833,0V3.972A.972.972,0,0,0,9.25,3ZM6.75,5.778a.972.972,0,0,0-.972.972v5.278A.973.973,0,0,0,6.75,13h5.278A.972.972,0,0,0,13,12.028V6.75a.972.972,0,0,0-.972-.972Zm-.139.972a.139.139,0,0,1,.139-.139h5.278a.139.139,0,0,1,.139.139v5.278a.139.139,0,0,1-.139.139H6.75a.139.139,0,0,1-.139-.139Z"
                            transform="translate(8 8)" fill="#00b754"
                            fill-rule="evenodd"/>
                    </svg>
                  </button>
                </el-tooltip>

                <el-tooltip v-if="status === 'past_projects'" class="item" effect="dark" content="Delete" placement="top">
                  <button v-if="showDeleteBtn(project)" style="border: none; background: none" @click.prevent="deleteProject(project)" class="me-2">
                    <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path opacity="0.1" d="M15.5 31C24.0604 31 31 24.0604 31 15.5C31 6.93959 24.0604 0 15.5 0C6.93959 0 0 6.93959 0 15.5C0 24.0604 6.93959 31 15.5 31Z" fill="#EB5757"/>
                      <path d="M12.125 10.25V9H18.375V10.25H21.5V11.5H20.25V20.875C20.25 21.0408 20.1842 21.1997 20.0669 21.3169C19.9497 21.4342 19.7908 21.5 19.625 21.5H10.875C10.7092 21.5 10.5503 21.4342 10.4331 21.3169C10.3158 21.1997 10.25 21.0408 10.25 20.875V11.5H9V10.25H12.125ZM11.5 11.5V20.25H19V11.5H11.5ZM13.375 13.375H14.625V18.375H13.375V13.375ZM15.875 13.375H17.125V18.375H15.875V13.375Z" fill="#00B050"/>
                    </svg>
                  </button>
                </el-tooltip>

                <el-tooltip v-else-if="status === 'deleted_projects' || status === 'draft_projects'" class="item" effect="dark" content="Permanently Delete" placement="top">
                  <button v-if="showPermanentlyDeleteBtn(project)" @click.prevent="openModal(project.id)" style="border: none; background: none">
                    <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path opacity="0.1" d="M15.5 31C24.0604 31 31 24.0604 31 15.5C31 6.93959 24.0604 0 15.5 0C6.93959 0 0 6.93959 0 15.5C0 24.0604 6.93959 31 15.5 31Z" fill="#EB5757"/>
                      <path d="M12.125 10.25V9H18.375V10.25H21.5V11.5H20.25V20.875C20.25 21.0408 20.1842 21.1997 20.0669 21.3169C19.9497 21.4342 19.7908 21.5 19.625 21.5H10.875C10.7092 21.5 10.5503 21.4342 10.4331 21.3169C10.3158 21.1997 10.25 21.0408 10.25 20.875V11.5H9V10.25H12.125ZM11.5 11.5V20.25H19V11.5H11.5ZM13.375 13.375H14.625V18.375H13.375V13.375ZM15.875 13.375H17.125V18.375H15.875V13.375Z" fill="#00B050"/>
                    </svg>
                  </button>
                </el-tooltip>

                <el-tooltip v-else class="item" effect="dark" content="Stop" placement="top">
                  <button v-if="showStopBtn(project)" @click.prevent="openStopModal(project.id)"
                          style="border: none; background: none">
                    <svg id="Group_52" data-name="Group 52"
                         xmlns="http://www.w3.org/2000/svg" width="31" height="31"
                         viewBox="0 0 31 31">
                      <path id="stop-circle-line"
                            d="M8.958,15.917a6.958,6.958,0,1,1,6.958-6.958A6.958,6.958,0,0,1,8.958,15.917Zm0-1.392A5.567,5.567,0,1,0,3.392,8.958,5.567,5.567,0,0,0,8.958,14.525ZM6.871,6.871h4.175v4.175H6.871Z"
                            transform="translate(7 7)" fill="#00b754"/>
                      <circle id="Ellipse_61" data-name="Ellipse 61" cx="15.5"
                              cy="15.5" r="15.5" fill="#eb5757" opacity="0.1"/>
                    </svg>
                  </button>
                </el-tooltip>
              </div>
            </td>
          </tr>
          <tr v-if="projects.length <= 0">
            <td colspan="9" class="text-danger text-center">No Project Available</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div id="paginate-content" class="d-flex justify-content-center">
        <el-pagination
            class="mt-3 custom-pagination"
            background
            layout="prev, pager, next"
            @current-change="handleCurrentChange"
            :total="pagination.last_page"
            :page-size="pagination.per_page"
        />
      </div>
    </div>

    <div v-else>

      <div>
        <div v-if="checkUserPermission(projectDetails.my_roles, ['COLLABORATOR'])">
          <upload-document
              :project="projectDetails"
              :title="'Active'"
              ref="uploadDocuments"
              @submitted="documentSubmitted"
              @close="showDetails = false"
          />
        </div>
        <div v-if="hostShow === true">
          <project-details
              ref="projectDetails"
              :project="projectDetails"
              :isCollaborator="checkUserPermission(projectDetails.my_roles, ['COLLABORATOR'])"
              :title="'Active'"
              @saved="decisionSaved"
              @close="showDetails = false"
          />
        </div>
      </div>

    </div>

    <stop-project-modal
        v-if="status === 'deleted_projects' || status === 'draft_projects'"
        :show="showModal"
        :loading="stopModalLoading"
        :text="`Are you sure you want to permanently delete this project?`"
        @close="showModal = false"
        @success="permanentlyDeleteProject"
    />
    <stop-project-modal
        v-else
        :show="showStopModal"
        :loading="stopModalLoading"
        @close="showStopModal = false"
        @success="stopProject"
    />

  </div>
</template>

<script>
import axios from "axios";
import {Notification} from "element-ui";
import ProjectDetails from "@/components/longleap/projects/ProjectDetails";
import UploadDocument from "@/components/longleap/projects/UploadDocument";
import StopProjectModal from "@/components/longleap/StopProjectModal.vue";

export default {
  name: "ProjectsList",

  watch: {
    $route: {
      deep: true,
      handler() {
        this.getProjects();
        this.showDetails = false;
      }
    }
  },
  computed: {
    projectsStatus() {
      if (this.status === 'active_projects') {
        return 'ACTIVE'
      } else if (this.status === 'draft_projects') {
        return 'DRAFT'
      } else if (this.status === 'past_projects') {
        return 'PAST'
      } else if (this.status === 'deleted_projects') {
        return 'DELETED'
      }
    }
  },
  data() {
    return {
      projects: [],
      pagination: {
        last_page: 1,
        per_page: 10,
      },
      host: null,
      showDetails: false,
      hostShow: true,
      projectDetails: {},
      showModal: false,
      stopModalLoading: false,
      activeSubscriptions: null,
      tempProjectId: null,
      showStopModal: false,
      firstLoading: false,
    }
  },
  props: {
    status: {
      type: String,
      required: true
    }
  },
  components: {
    StopProjectModal,
    ProjectDetails,
    UploadDocument,
  },
  mounted() {
    this.getProjects();
    this.checkSubscription();

  },
  methods: {
    documentSubmitted() {
      if (this.$refs.projectDetails) {
        this.$refs.projectDetails.getProjectDetails()
      }
    },

    decisionSaved() {
      if (this.$refs.uploadDocuments) {
        this.$refs.uploadDocuments.getProject()
      }
    },

    checkUserPermission(roles, userTypes) {
      return roles.some((role) => userTypes.includes(role.user_type));
    },

    showEditBtn(project) {

      if (project.status !== "DELETED")
      {
        return this.checkUserPermission(project.my_roles, ["HOST", "TEAM_MEMBER", "SUPPORT_STAFF"])
      }
    },

    showPermanentlyDeleteBtn(project) {
      return this.checkUserPermission(project.my_roles, ["HOST", "TEAM_MEMBER"])
    },

    permanentlyDeleteProject() {
      // this.showMessageModalLoading = true;
      // console.log(this.tempProjectId)
      axios.post('/projects/delete-permanently', {
        project_id: this.tempProjectId,
      })
          .then((res) => {
            // console.log(res.data)
            Notification.success('Project deleted successfully!');
            this.getProjects();
          })
          .catch((error) => {
            console.log(error.response);
            Notification.error('Something went wrong!');
          })
          .finally(() => {
            // this.showMessageModalLoading = false;
            this.showModal = false;
          })
    },

    showCopyBtn(project) {
      return this.checkUserPermission(project.my_roles, ["HOST", "TEAM_MEMBER"])
    },

    showStopBtn(project) {
      return this.checkUserPermission(project.my_roles, ["HOST", "TEAM_MEMBER"])
    },

    showDeleteBtn(project) {
      return this.checkUserPermission(project.my_roles, ["HOST", "TEAM_MEMBER"])
    },

    deleteProject(project) {
      axios.post('projects/delete', {
        project_id: project.id,
      })
          .then(() => {
            if (this.$route.query.id) {
              this.$router.push(`/team/projects?team=${this.$route.query.team}&id=${this.$route.query.id}&menu=projects&active=deleted_projects`);
            } else {
              this.$router.push('/personal-project/dashboard?menu=projects&active=deleted_projects');
            }
          })
          .catch(() => {
            Notification.error('Something went wrong!');
          })
    },

    getProjects(page = 1) {
      this.firstLoading = true;

      // console.log('this.$route', this.$route)
      let team = this.$route.query.id ? this.$route.query.id : null;
      axios.get(`projects?page=${page}&status=${this.projectsStatus}${team ? '&team='+this.$route.query.id : ''}`)
          .then(res => {
            // console.log('res',res)
            this.projects = res.data.data;
            this.host = res.data.host;
            this.pagination.last_page = res.data.last_page;
            this.pagination.per_page = res.data.per_page;
          })
          .catch(() => {
            Notification.error('No Project Found!');
          })
          .finally(() => {
                    this.firstLoading = false;
                })
    },

    handleCurrentChange(page) {
      this.getProjects(page);
    },

    editProject(project) {
      if (this.$route.query.team) {
        this.$router.push(`/team/projects?team=${this.$route.query.team}&id=${project.team_id}&menu=${(project.project_type == 'PUBLIC') ? 'createPublicProject' : 'createPrivateProject'}&active=primary_info&project_id=${project.id}&type=edit`);
      } else {
        this.$router.push(`/personal-project/dashboard?menu=${(project.project_type == 'PUBLIC') ? 'createPublicProject' : 'createPrivateProject'}&active=primary_info&project_id=${project.id}&type=edit`)
      }
    },

    copyProject(project) {
      axios.post(`/projects/duplicate/${project.id}`, project)
          .then((response) => {
            response = response.data;
            if (!response.success && response.message) {
              Notification.success(response.message)
              // console.log(response)
              this.projects.push(response.project)
              if (this.$route.query.team) {
                this.$router.push(`/team/projects?team=${this.$route.query.team}&id=${this.$route.query.id}&menu=projects&active=draft_projects`);
              } else {
                this.$router.push('/personal-project/dashboard?menu=projects&active=draft_projects');
              }
            }
          })
    },

    openModal(projectId) {
      this.tempProjectId = projectId;
      this.showModal = true;
    },
    openStopModal(projectId) {
      this.tempProjectId = projectId;
      this.showStopModal = true;
    },
    checkSubscription() {
      this.firstLoading = true;
      axios.get('/subscriptions/subscribed')
          .then(res => {
            this.activeSubscriptions = res.data.activeSubscriptions;
          })
          .finally(() => {
            this.firstLoading = false;
          })
    },
    stopProject() {
      axios.post('/projects/stop', {
        project_id: this.tempProjectId,
      })
          .then(() => {
            this.showStopModal = false;
            if (this.$route.query.team) {
              this.$router.push(`/team/projects?team=${this.$route.query.team}&id=${this.$route.query.id}&menu=projects&active=past_projects`);
            } else {
              this.$router.push('/personal-project/dashboard?menu=projects&active=past_projects');
            }
          })
          .catch(() => {
            Notification.error('Something went wrong!');
          })
    },

    approvedDocumentCount(project) {
      let documentArray = [];
      project.documents.forEach((document) => {
        if (document.status === 'APPROVED') {
          documentArray.push(document)
        }
      })
      return documentArray.length
    },

    isHostOrStaff(project) {
      project.users.forEach((user) => {
        if (user.pivot.user_type === 'SUPPORT_STAFF') {
          return "Support Staff";
        } else if (user.pivot.user_type === 'COLLABORATOR') {
          return "Collaborator";
        }
      })
    },

    viewDetails(project) {
      this.showDetails = true;
      this.hostShow = false;
      this.getUserType(project.my_roles);
      if (
        this.checkUserPermission(project.my_roles, ["HOST", "TEAM_MEMBER", "SUPPORT_STAFF"])
      ) {
        this.hostShow = true;
      }
      this.projectDetails = project;
    },

    getUserType(myRoles) {
      let roleTxt = '';
      let userTypes = {HOST: 'Host', COLLABORATOR: 'Collaborator', SUPPORT_STAFF: 'Support Staff', TEAM_MEMBER: "Team Member"}
      myRoles.forEach((role, index) => {
        roleTxt += userTypes[role.user_type];
        roleTxt += role.scopes ? ': ' + role.scopes : '';
        roleTxt += index + 1 < myRoles.length ? ", " : "";
      });
      return roleTxt;
    }

  },


}
</script>

<style scoped>
.sidebar-title {
  font-size: 18px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  color: #092C4C;
}


</style>