<template>
    <el-form ref="form" :model="formData"
        :rules="rules"
        class="project-form overflow-hidden"
        label-position="top">
        <el-form-item label="Enter Project ID"
            prop="pid">
            <el-input
                v-model="formData.pid" />
        </el-form-item>

        <div
            class="d-flex justify-content-end">
            <el-button type="default"
                @click="handleClose">Close
            </el-button>
            <el-button type="success" :loading="searching"
                @click="locatePID('form')">
                Locate</el-button>
        </div>
    </el-form>
</template>

<script>
import { Notification } from "element-ui";
import axios from "axios";

export default {
    name: "AddExternalProjectModal",
    data() {
        return {
            formData: {
                pid: "",
            },
            rules: {
                pid: [
                    { required: true, message: "Please input project ID" },
                    { min: 1, max: 11, message: "Project ID must be 1 to 11 characters" },
                ],
            },
            searching:false,
        };
    },
    props: {
        show: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        handleClose() {
          this.$router.push('/personal-project/dashboard?menu=projects&active=active_projects');
        },
        locatePID(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
            this.searching=true;

                    axios
                        .post("/projects/verify-pid", this.formData)
                        .then((res) => {
                          res = res.data
                          if (res.status == 'failed') {
                            Notification.error(res.message);
                            return
                          }
                            if (this.$route.query.hasOwnProperty('team')) {
                                this.$router.replace({
                                    query: {
                                        menu: 'joinWithExternalLinkDescription',
                                        pid: this.formData.pid,
                                        team: this.$route.query.team,
                                        id: this.$route.query.id
                                    }
                                })
                            } else {
                                this.$router.replace({ query: { menu: 'joinWithExternalLinkDescription', pid: this.formData.pid } })
                            }

                            this.$emit("close");
                        })
                        .catch((error) => {
                            Notification.error(error.response.data.message);
                        })
                        .finally(() => {
                            this.searching = false;
                        });
                } else {
                    Notification.warning("Please input all required fields");
                    return false;
                }
            });
        },
    },
};
</script>

<style lang="scss">

</style>
