<template>
    <div class="position-relative">
        <div v-if="firstLoading"
             class="w-100 h-100 position-absolute bg-black d-flex align-items-center justify-content-center bg-white"
             style="z-index: 10000;">
            <i style="font-size: 25px" class="el-icon-loading text-black"></i>
        </div>

        <div class="d-flex my-4 align-items-center justify-content-center">
            <h2 class="step-title m-0">Project Summary</h2>
        </div>

        <div class="row">
            <div class="col-md-12 col-lg-6 offset-lg-3">
                <div class="project-summary-card">
                    <table class="table table-sm table-borderless m-0">
                        <tbody>
                        <tr>
                            <td><h3 class="card-title m-0">Project Overview</h3></td>
                            <td>
                                <el-tag v-if="project.project_type == 'PUBLIC' && project.pid !== null && project.status !== 'DRAFT'">Project Id:
                                    {{ project.pid }}
                                </el-tag>
                            </td>
                        </tr>
                        <tr>
                            <td>PROJECT NAME</td>
                            <td>{{ project.hasOwnProperty('title') ? project.title : "" }}</td>
                        </tr>
                        <tr>
                            <td>COLLABORATORS</td>
                            <td>{{
                                project.hasOwnProperty('total_collaborators') ? project.total_collaborators : ""
                                }}
                            </td>
                        </tr>
                        <tr>
                            <td>SUPPORT STAFF</td>
                            <td>{{
                                project.hasOwnProperty('total_support_staff') ? project.total_support_staff : ""
                                }}
                            </td>
                        </tr>
                        <tr>
                            <td>DOCUMENTS TO COLLECT</td>
                            <td>{{ project.hasOwnProperty('documents') ? project.documents.length : 0 }}</td>
                        </tr>
                        <tr>
                            <td>Project Status</td>
                            <td>
                                <el-tag :type="project.status === 'ACTIVE' ? 'success' : 'info'">{{
                                    project.status
                                    }}
                                </el-tag>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div v-if="showFooterActionBtn && project.status === 'DRAFT'" class="d-flex justify-content-end">
            <el-button
                    class="mt-2"
                    type="success"
                    :loading="loading"
                    @click="handleSubmit('form')">Start Project
            </el-button>
        </div>

        <div v-if="showFooterActionBtn && project.status === 'ACTIVE'" class="d-flex justify-content-end">
            <el-button
                    class="mt-2"
                    type="success"
                    :loading="loading"
                    @click="update">
              Go to Project Summary
            </el-button>
        </div>

        <!--        Modal-->
        <pricing-modal
                :show="showPricingModal"
                :plans="plans"
                :active-subscriptions="activeSubscriptions"
                :free-subscription="freeSubscription"
                :pending-subscriptions="pendingSubscriptions"
                @close="showPricingModal = false"
                @openSelectCardModal="openSelectCardModal"
        />

        <information-modal
                :show="showInformationModal"
                @close="redirectToProjectsTab"
        />

        <add-new-card-modal
                :show="showAddNewCardModal"
                @close="showAddNewCardModal = false"
                @success="showPricingModal = true"
        />
    </div>
</template>

<script>
import axios from "axios";
import {Notification} from "element-ui";
// import PricingModal from "@/components/personal/modal/PricingModal";
import InformationModal from "@/components/longleap/InformationModal";
import moment from "moment";
import AddNewCardModal from "@/components/pricing/AddNewCardModal";
import ProjectService from "@/services/project.service";

export default {
    name: "ProjectSummary",
    components: {InformationModal, AddNewCardModal},
    data() {
        return {
            project: {},
            projects: [],
            loading: false,
            upgrade: false,
            showInformationModal: false,
            showPricingModal: false,
            showFooterActionBtn: true,
            informationModalText: "",
            activeSubscriptions: {},
            freeSubscription: {},
            pendingSubscriptions: [],
            plans: [],
            showAddNewCardModal: false,
            showCardModal: false,
            upgradeOrDowngrade: "downgrade",
            paymentMethods: [],
            selectPlan: {},
            firstLoading: false
        }
    },
    mounted() {
        if (this.$route.query.hasOwnProperty('project_id')) {
            this.getProject(this.$route.query.project_id);
            // this.getProjectsSummary();
            // this.checkSubscription();
            // this.getPlans();
        } else {
            Notification.error('Invalid Project ID');
            if (this.$route.query.team) {
              this.$router.push(`/team/projects?team=${this.$route.query.team}&id=${this.$route.query.id}&menu=createPublicProject&active=primary_info`);
            } else {
              this.$router.push(`/personal-project/dashboard?menu=${this.$route.query.menu}&active=primary_info`);
            }
        }
    },
    methods: {
      update() {
        if (this.$route.query.team) {
          this.$router.push(`/team/projects?team=${this.$route.query.team}&id=${this.$route.query.id}&menu=projects&active=active_projects`)
        } else {
          this.$router.push(`/personal-project/dashboard?menu=projects&active=active_projects`)
        }
      },
        editProject(project) {
          if (this.$route.query.team) {
            this.$router.push(`/team/projects?team=${this.$route.query.team}&id=${project.team_id}&menu=${(project.project_type == 'PUBLIC')?'createPublicProject':'createPrivateProject'}&active=primary_info&project_id=${project.id}&type=edit`);
          } else {
            this.$router.push(`/personal-project/dashboard?menu=${this.$route.query.menu}&active=primary_info&project_id=${project.id}&type=edit`)
          }
        },
        redirectToProjectsTab() {
            this.showInformationModal = false;
            if (this.$route.query.team) {
              this.$router.push(`/team/projects?team=${this.$route.query.team}&id=${this.$route.query.id}&menu=projects&active=active_projects`);
            } else {
              this.$router.push('/personal-project/dashboard?menu=projects&active=active_projects');
            }
        },
        addNewCard() {
            this.showCardModal = false;
            this.showAddNewCardModal = true;
        },
        subscribeSuccess() {
            this.showCardModal = false;
            this.checkSubscription();
        },
        openSelectCardModal(value) {
            this.upgradeOrDowngrade = value[0];
            this.paymentMethods = value[1];
            this.selectPlan = value[2];
            this.showPricingModal = false;
            this.showCardModal = true;
        },
        getProject(id) {
            this.firstLoading = true;
            ProjectService.getProject(id)
                .then(res => {
                    this.project = res.data;
                })
                .catch((error) => {
                    if (error.response.data.errors) {
                        Notification.error(Object.values(error.response.data.errors)[0][0]);
                    } else {
                        Notification.error(error.response.data.message);
                    }
                })
                .finally(() => {
                    this.firstLoading = false;
                })
        },

        // getProjectsSummary() {
        //     this.firstLoading = true;
        //     this.loading = true;
        //     axios.get(`/projects/projects-summary`)
        //         .then(response => {
        //             this.projects = response.data;
        //         })
        //         .catch((error) => {
        //             if (error.response.data.errors) {
        //                 Notification.error(Object.values(error.response.data.errors)[0][0]);
        //             } else {
        //                 Notification.error(error.response.data.message);
        //             }
        //         })
        //         .finally(() => {
        //             this.loading = false;
        //             this.firstLoading = false;
        //         });
        // },

        getPlans() {
            this.firstLoading = true;
            axios.get('/subscriptions/pricing')
                .then(response => {
                    this.plans = response.data.personal;
                })
                .finally(() => {
                    this.firstLoading = false;
                })
        },

        handleSubmit() {
            if (this.upgrade) {
                this.loading = true;
                this.callToStripPaymentMethod();
            } else {
                if (this.project.hasOwnProperty('id') && this.project.status === 'DRAFT') {
                    if (this.project.end_date_first == null) {
                        Notification.error("Please add project deadline date in step-3");
                    } else if (this.project.reminder_lw_first == null) {
                        Notification.error("Please add reminder days in the last week of the deadline in step-3");
                    } else if (this.$route.query.menu === 'createPrivateProject' && this.project.collaborators.length == 0) {
                        Notification.error("Please add at least one collaborator in step-4");
                    } else if (this.project.documents.length == 0) {
                        Notification.error("Please upload at least one document in step-5");
                    } else {
                        this.activeProject();
                    }
                }
                if (this.project.hasOwnProperty('id') && this.project.status === 'ACTIVE') {
                    // Notification.info('This project is already active!')
                }
            }
        },

        callToStripPaymentMethod() {
            axios.get("//subscriptions/payment-method")
                .then((response) => {
                    if (response.data.length > 0) {
                        this.showPricingModal = true;
                    } else {
                        this.showAddNewCardModal = true;
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        activeProject() {
            this.loading = true;
            let data = JSON.parse(JSON.stringify(this.project));
            data.status = 'ACTIVE';
            data.emailStatus = true;
            data.start_date = moment().format('YYYY-MM-DD');

            axios.put(`/projects/update/${this.project.id}`, data)
                .then((response) => {
                    response = response.data;
                    if (!response.success && response.message) {
                        Notification.error(response.message)
                    } else {
                      if (this.$route.query.team) {
                        this.$router.push(`/team/projects?team=${this.$route.query.team}&id=${this.$route.query.id}&menu=projects&active=active_projects`);
                      } else {
                        this.$router.push(`/personal-project/dashboard?menu=projects&active=active_projects`);
                      }
                    }
                })
                .catch(error => {
                    if (error.response.data.errors) {
                        Notification.error(Object.values(error.response.data.errors)[0][0]);
                    } else {
                        Notification.error(error.response.data.message);
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        checkSubscription() {
            this.firstLoading = true;
            axios.get('/subscriptions/subscribe')
                .then(res => {
                    this.activeSubscriptions = res.data.activeSubscriptions;
                    this.freeSubscription = res.data.freeSubscription;
                    this.pendingSubscriptions = res.data.pendingSubscriptions;
                })
                .finally(() => {
                    this.firstLoading = false;
                })
        },
    },
    watch: {
        'project': {
            handler(value) {
                this.showFooterActionBtn = value.hasOwnProperty('id');
            },
            immediate: false
        }
    }
}
</script>

<style scoped>

</style>