<template>
  <div class="position-relative">
    <div v-if="firstLoading"
         class="w-100 h-100 position-absolute bg-black d-flex align-items-center justify-content-center bg-white"
         style="z-index: 10000;">
      <i style="font-size: 25px" class="el-icon-loading text-black"></i>
    </div>

    <div class="d-flex my-4 align-items-center justify-content-center">
      <h2 class="step-title m-0">Add Collaborators (Required)</h2>
      <el-tag v-if="project.hasOwnProperty('status')" style="margin-left: 15px">{{ project.status }}</el-tag>
    </div>

    <div class="row">
      <div class="col-12 mb-3 text-center">Total Number of Potential Collaborators</div>
      <div class="col-md-4 offset-md-4">
        <el-input-number class="w-100" v-model="rowNumber" :min="1"></el-input-number>
      </div>
      <div class="col-md-4">
        <el-button
            v-if="$route.query.menu === 'createPrivateProject'"
            @click.prevent="addRow"
            type="success"
            plain
        >Add Rows
        </el-button>
      </div>
    </div>

    <el-form
        ref="form"
        :model="formData"
        :rules="rules"
        class="mt-3 project-form"
        label-position="top"
    >
      <div class="px-2 pt-1 border rounded mb-1" v-for="(user, index) in formData.users" :key="index">
        <div class="row position-relative">
          <div class="col-md-12 right-padding-row">
            <div class="row">
              <div class="col-md-6 col-xl-3">
                <el-form-item :error="user.emailError" label="Email" prop="email">
                  <el-input @blur="remoteMethod($event.target.value, index)" placeholder="Search or Add Email" v-model="user.email"/>
                </el-form-item>
              </div>
              <div class="col-md-6 col-xl-3">
                <el-form-item :error="user.first_nameError" label="First Name" prop="first_name">
                  <el-input :disabled="!!user.id" v-model="user.first_name"/>
                </el-form-item>
              </div>
              <div class="col-md-6 col-xl-3">
                <el-form-item :error="user.last_nameError" label="Last Name" prop="last_name">
                  <el-input :disabled="!!user.id" v-model="user.last_name"/>
                </el-form-item>
              </div>
              <div class="col-md-6 col-xl-3">
                <el-form-item :error="user.organizationError" label="Organization" prop="organization">
                  <el-input :disabled="!!user.id" v-model="user.organization"/>
                </el-form-item>
              </div>
            </div>
          </div>
          <div class="row-action-div d-flex align-items-center justify-content-center">
            <div class="d-flex">
              <div
                  class="me-2 cursor-pointer"
                  @click="arrayMove(index, 'up')"
                  v-if="formData.users.length === (index + 1) && formData.users.length > 1"
              >
                <img style="width: 22px; height: 22px" src="@/assets/img/up-arrow.png" alt=""/>
              </div>
              <div
                  class="me-2 cursor-pointer"
                  @click="arrayMove(index, 'down')"
                  v-if="formData.users.length !== (index + 1) && formData.users.length > 1"
              >
                <img style="width: 22px; height: 22px" src="@/assets/img/down-arrow.png" alt=""/>
              </div>
              <div @click.prevent="removeRow(user, index)" style="cursor: pointer">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M5 2V0H15V2H20V4H18V19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H3C2.73478 20 2.48043 19.8946 2.29289 19.7071C2.10536 19.5196 2 19.2652 2 19V4H0V2H5ZM4 4V18H16V4H4ZM7 7H9V15H7V7ZM11 7H13V15H11V7Z"
                      fill="#00B050"/>
                </svg>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="d-flex justify-content-end">
        <el-button
            class="mt-2"
            type="success"
            :loading="loading"
            @click="handleSubmit()">Save & Next
        </el-button>
        <el-button
            v-if="project.hasOwnProperty('status') && project.status !== 'ACTIVE'"
            class="mt-2"
            type="success"
            :loading="loading"
            @click="handleSubmit('draft')">Save as Draft
        </el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import {Notification, Option, Select} from "element-ui";
import axios from "axios";
import ProjectService from "@/services/project.service";
import Vue from "vue";

export default {
  name: "AddCollaborators",
  components: {
    ElSelect: Select,
    ElOption: Option
  },
  data() {
    return {
      formData: {
        users: [],
        projectId: this.$route.query.project_id,
        type: 'collaborator',
        total_collaborators: 0,
        team_id: this.$route.query.id
      },
      rules: {},
      loading: false,

      rowNumber: 1,

      foundError: false,
      project: {},

      users: [],
      usersLoading: false,

      focusIndex: null,
      firstLoading: false
    }
  },
  mounted() {
    if (this.$route.query.hasOwnProperty('project_id')) {
      this.getProject(this.$route.query.project_id);
    } else {
      Notification.error('Invalid Project ID');
      if (this.$route.query.team) {
        this.$router.push(`/team/projects?team=${this.$route.query.team}&id=${this.$route.query.id}&menu=${this.$route.query.menu}&active=primary_info`);
      } else {
        this.$router.push('/personal-project/dashboard?menu=createPrivateProject&active=primary_info');
      }
    }
  },
  methods: {
    remoteMethod(query, index) {
      this.formData.users[index].id = null;
      this.formData.users[index].first_name = null;
      this.formData.users[index].last_name = null;
      this.formData.users[index].organization = null;
      this.formData.users[index].email = query;
      if (query !== '') {
        this.usersLoading = true;
        setTimeout(() => {
          this.callToSearchUsers(query, true, index);
        }, 200);
      } else {
        this.options = [];
      }
    },

    callToSearchUsers(email, blur = false, index = 0) {
      axios.get(`/users?email=${email}`)
          .then(response => {
            this.users = response.data;
            if (blur && response.data.length) {
              Vue.set(this.formData.users, index, response.data[0])
            }
          })
          .finally(() => {
            this.usersLoading = false;
          });
    },

    arrayMove(index, direction) {
      let temp = this.formData.users[index];
      this.formData.users.splice(index, 1);
      if (direction === 'up') {
        this.formData.users.splice(index - 1, 0, temp);
      } else {
        this.formData.users.splice(index + 1, 0, temp);
      }
    },

    getProject(id) {
      this.firstLoading = true;
      ProjectService.getProject(id)
          .then(res => {
            this.project = res.data;
            if (res.data.users && res.data.users.length) {
              this.rowNumber = this.project.total_collaborators;

              if (res.data.collaborators.length) {
                this.formData.users = [];
                res.data.collaborators.forEach(user => {
                  this.formData.users.push({
                    id: user.id,
                    email: user.email,
                    first_name: user.first_name,
                    last_name: user.last_name,
                    organization: user.organization,

                    emailError: '',
                    first_nameError: '',
                    last_nameError: '',
                    organizationError: '',
                  });
                })
              }
            }
          })
          .catch((error) => {
            if (error.response.data.errors) {
              Notification.error(Object.values(error.response.data.errors)[0][0]);
            } else {
              Notification.error(error.response.data.message);
            }
          })
          .finally(() => {
            this.firstLoading = false;
          })
    },

    handleSubmit(type = 'save') {
      // console.log(this.formData.users , "these aer usrs")
      // if (this.$route.query.menu === 'createPrivateProject') {
      let validateStatus = true;
      // if (type == 'save') {
        if (this.project.project_type === 'PRIVATE') {
          if (this.formData.users.length > 0) {
            validateStatus = true
          } else {
            Notification.error("Please add at least one collaborator");
            return;
          }
        }

        if (validateStatus == true) {
          this.foundError = false;
          this.formData.users.forEach((user, index) => {
            this.validationCheck('email', index);
            this.validationCheck('first_name', index);
            this.validationCheck('last_name', index);
            // this.validationCheck('organization', index);
          })

          if (!this.foundError) {
            this.loading = true;
            this.formData.total_collaborators = this.rowNumber
            this.callToBackendApi(type);
          }
        } else {
          this.$refs.form.validate((valid) => {
            if (valid) {
              this.loading = true;
              let data = this.project;
              if (this.project.project_type === 'PUBLIC') {
                data.total_collaborators = this.rowNumber
              } else {
                data.total_collaborators = this.formData.total_collaborators;
              }

              axios.put(`/projects/update/${this.project.id}`, data)
                  .then(response => {
                    if (this.$route.query.team) {
                      if (type === 'save') {
                        this.$router.push(`/team/projects?team=${this.$route.query.team}&id=${this.$route.query.id}&menu=${this.$route.query.menu}&active=list_required_docs&project_id=${this.$route.query.project_id}`);
                      } else {
                        this.$router.push(`/team/projects?team=${this.$route.query.team}&id=${this.$route.query.id}&menu=projects&active=draft_projects`);
                      }
                    } else {
                      if (type === 'save') {
                        this.$router.push(`/personal-project/dashboard?menu=${this.$route.query.menu}&active=list_required_docs&project_id=${this.project.id}`);
                      } else {
                        this.$router.push('/personal-project/dashboard?menu=projects&active=draft_projects');
                      }
                    }
                  })
                  .catch(error => {
                    if (error.response.data.errors) {
                      Notification.error(Object.values(error.response.data.errors)[0][0]);
                    } else {
                      Notification.error(error.response.data.message);
                    }
                  })
                  .finally(() => {
                    this.loading = false;
                  });

            } else {
              Notification.warning('Please input all required fields');
              return false;
            }
          });
        }
      // }
    },
    validationCheck(property, index) {
      let user = this.formData.users[index];
      if (property == 'email' && !/^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(user[property])) {
        this.foundError = true;
        user[property + 'Error'] = 'Please enter valid email';
      } else if (user[property] === "" || user[property] === null) {
        this.foundError = true;
        user[property + 'Error'] = 'This field is required';
      } else {
        user[property + 'Error'] = '';
      }
    },

         callToBackendApi(type) {
              axios.post('/projects/members', this.formData)
                  .then(response => {
                    response = response.data;
            if (response.success) {
              if (this.$route.query.team) {
                if (type === 'save') {
                  this.$router.push(`/team/projects?team=${this.$route.query.team}&id=${this.$route.query.id}&menu=${this.$route.query.menu}&active=list_required_docs&project_id=${this.$route.query.project_id}`);
                } else {
                  this.$router.push(`/team/projects?team=${this.$route.query.team}&id=${this.$route.query.id}&menu=projects&active=draft_projects`);
                }
              } else {
                if (type === 'save') {
                  this.$router.push(`/personal-project/dashboard?menu=${this.$route.query.menu}&active=list_required_docs&project_id=${this.project.id}`);
                } else {
                  this.$router.push('/personal-project/dashboard?menu=projects&active=draft_projects');
                }
              }
            } else {
              Notification.error(response.message);
            }
          })
          .catch(error => {
            console.log(error.response.data)
          })
          .finally(() => {
            this.loading = false;
          })
    },

    addRow() {
      let userCount = this.formData.users.length;

      if (this.rowNumber < userCount) {
        Notification.info(`You already added ${userCount} rows, If want to reduce the number of rows then you've to delete the row manually`);
      } else {
        let count = parseInt(this.rowNumber) - parseInt(userCount);
        for (let i = 0; i < count; i++) {
          this.formData.users.push({
            id: '',
            email: '',
            first_name: '',
            last_name: '',
            organization: '',

            emailError: '',
            first_nameError: '',
            last_nameError: '',
            organizationError: '',
          });
        }
      }
    },

    removeRow(user, index) {
      if (user.id) {
        axios.delete(`projects/members/${user.id}?projectId=${this.project.id}`)
            .then(response => {
              this.formData.users.splice(index, 1);
            })
            .catch(error => {
              console.log(error.response.data)
            })
      } else {
        this.formData.users.splice(index, 1);
      }

    }
  }
}
</script>

<style scoped>

</style>