<template>
  <div>
    <div class="d-flex align-items-center justify-content-between mb-3">
      <h3 @click="$emit('close')" style="cursor:pointer;" class="card-title mb-3"><i class="fa fa-angle-left"></i>
        {{ project.title }}</h3>
    </div>
    <p class="fw-bold mb-1">Project Summary</p>
    <p class="mb-3">{{ project.project_summary }}</p>
    <div v-if="attachments.length > 0">
      <p class="fw-bold mb-2">Shared Documents For Review</p>
      <ul class="list project-doc-list">
        <li v-for="doc in attachments">
          <el-button
              @click.prevent="attachmentDownload(doc)"
              type="text">{{ doc.original_name }}
          </el-button>
        </li>
      </ul>
    </div>
    <p class="fw-bold mb-2">Upload Documents</p>
    <div class="table-responsive">
      <table class="table border custom-table">
        <tr>
          <th>Document Name</th>
          <th>Max file size</th>
          <th>File type</th>
          <th>Template</th>
          <th>Upload</th>
          <th>Status</th>
          <th>Host Comment</th>
        </tr>
        <tr v-for="(document) in documents" :key="document.id">
          <td>{{ document.title }}</td>
          <td>{{ document.max_size }} {{ document.size_unit }}</td>
          <td>
            <el-select
                class="ex_field"
                v-model="document.formats"
                placeholder="Select"
                multiple
                disabled
            >
              <el-option
                  v-for="(item, index) in fileTypes"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
              >
              </el-option>
            </el-select>
          </td>
          <td>
            <el-button
                @click.prevent="download(document)"
                type="text">{{ document.original_name }}
            </el-button>
          </td>
          <td>
            <el-upload
                v-if="document.submitFileId == null"
                class="upload-demo"
                :headers="{'Authorization': token}"
                :action="uploadUrl"
                :on-change="handleChange"
                :show-file-list="false"
                :data="document"
            >
              <el-button
                  :disabled="document.title === ''"
                  type="default"
                  :loading="loading"
              >Upload <i class="el-icon-upload"></i></el-button>
            </el-upload>
            <div v-else>
              <div class="mb-2" v-if="document.submitFileStatus === 'SUBMITTED' && document.host_status !== 'REJECTED'">
                <el-button
                    @click.prevent="removeFile(document)"
                    type="danger"
                    disabled
                >Delete File
                </el-button>
              </div>
              <div class="mb-2" v-else>
                <el-button
                    @click.prevent="removeFile(document)"
                    type="danger"
                    :disabled="document.notes.length > 0 && document.host_status !== 'REJECTED'">
                    Delete File
                </el-button>
              </div>

              <el-button
                  class="d-block text-dark"
                  type="text"
                  @click.prevent="download(document, 'submitFileName')"
              >{{ document.submitFileName }}
              </el-button>
            </div>
          </td>
          <td>
            <div class="text-uppercase">
              {{
                document.notes.length > 0 ? document.host_status : document.submitFileStatus === null ? 'PENDING' : document.submitFileStatus
              }}
            </div>
          </td>
          <td>{{ document.host_comment }}</td>
        </tr>
      </table>
    </div>
    <div class="d-flex justify-content-end">
      <el-button
          class="mt-2"
          type="success"
          :disabled="loading || allSubmitted"
          :loading="submitLoading"
          @click.prevent="submitDocument"
      >{{ allSubmitted ? 'Documents Submitted' : 'Submit' }}
      </el-button>
    </div>
  </div>
</template>

<script>
import {Select, Option, Notification} from "element-ui";
import axios from "axios";
import {mapGetters} from "vuex";

export default {
  name: "UploadDocument",
  props: ['project', 'title'],
  components: {
    ElSelect: Select,
    ElOption: Option
  },
  data() {
    return {
      documents: [],
      uploadUrl: `${process.env.VUE_APP_API_BASE_URL}/projects/document-files-temp`,
      isDocumentUploaded: false,
      totalAllowedDoc: 0,
      commonFileType: {
        "application/doc": ["application/doc", "application/ms-doc", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"],
        "application/vnd.ms-excel": ["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"],
        "application/vnd.ms-powerpoint": ["application/vnd.ms-powerpoint", "application/vnd.openxmlformats-officedocument.presentationml.presentation", "application/vnd.openxmlformats-officedocument.presentationml.slideshow"]

      },
      fileTypes: [
        {
          value: "image/jpeg",
          label: "JPEG"
        },
        {
          value: "image/png",
          label: "PNG"
        },
        {
          value: "application/pdf",
          label: "PDF"
        },
        {
          value: "application/doc",
          label: "MS Word"
        },
        {
          value: "application/ms-doc",
          label: "MS Word (.docx)"
        },
        {
          value: "application/msword",
          label: "MS Word"
        },
        {
          value: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          label: "MS Word (OpenXML)"
        },
        {
          value: "application/vnd.ms-excel",
          label: "MS Excel"
        },
        {
          value: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          label: "MS Excel (OpenXML)"
        },
        {
          value: "application/vnd.ms-powerpoint",
          label: "MS PowerPoint"
        },
        {
          value: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
          label: "MS PowerPoint (OpenXML)"
        },
        {
          value: "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
          label: "MS PowerPoint (OpenXML)"
        }
      ],
      attachments: [],
      loading: false,
      submitLoading: false
    }
  },
  computed: {
    ...mapGetters("auth", ["token"]),
    allSubmitted() {
      return this.documents.every((document) => document.submitFileStatus === 'SUBMITTED')
    }
  },
  mounted() {
    this.getProject();
  },
  methods: {
    submitDocument() {
      this.submitLoading = true;
      let uuid = [];
      this.documents.forEach(doc => {
        if (doc.submitFileStatus === 'UPLOAD') {
          uuid.push(doc.uuid);
        }
      })
      axios.post('/projects/document-file-submit', {uuid: uuid, projectId: this.project.id})
          .then(response => {
            this.submitLoading = false;
            this.$emit('submitted')
            Notification.success(response.data.message);
            this.getProject();
          }).catch((err) => {
        if (err.response && err.response.status === 422) {
          Notification.error(err.response.data.message);
        }
        this.submitLoading = false;
      })
    },

    getProject() {
      axios.get('/projects/get/collaborator/doc/' + this.project.id)
          .then((res) => {
            this.documents = [];
            this.attachments = res.data.attachments;
            res.data.docs.forEach(doc => {
              this.documents.push(
                  {
                    id: doc.id,
                    uuid: doc.uuid,
                    submitDocId: null,
                    title: doc.title,
                    original_name: doc.original_name,
                    status: doc.status,
                    max_size: doc.max_size,
                    size_unit: doc.size_unit,
                    formats: doc.formats.split(','),
                    submitFileId: null,
                    submitFileName: null,
                    submitFileStatus: null,
                    host_status: null,
                    host_comment: null,
                    link: doc.link,
                    notes: [],
                    template: doc.template
                  }
              );
            });
            this.isDocumentUploaded = (this.documents.length == 0) ? true : false;
            if (res.data.submitDocs.length) {
              let tmpUploaded = 0;
              this.documents.forEach((doc, index) => {
                res.data.submitDocs.forEach((submitDoc) => {
                  if (doc.uuid === submitDoc.uuid) {
                    this.documents[index].submitFileId = submitDoc.id;
                    this.documents[index].submitFileName = submitDoc.original_name;
                    this.documents[index].submitted_link = submitDoc.link;
                    this.documents[index].submitFileStatus = submitDoc.status;
                    this.documents[index].notes = submitDoc.notes;
                    this.documents[index].submitDocId = submitDoc.id;
                    this.documents[index].host_status = submitDoc.host_status;
                    this.documents[index].host_comment = submitDoc.host_comment;
                    if (submitDoc.status == 'SUBMITTED' && submitDoc.host_status !== 'REJECTED') {
                      tmpUploaded++;
                    }
                  }
                })
              })
              this.isDocumentUploaded = (tmpUploaded < this.documents.length) ? false : true;
            }
          })
          .catch((error) => {
            console.log(error)
          })
    },

    download(file, nameKey = "original_name") {
      axios.get(`download/file/${file.id}`, {responseType: 'blob'})
          .then(response => {            
            this.downloadFile(response, file, nameKey);
          }).catch(err => {
        console.log(err)
      })
    },

    attachmentDownload(file) {
      // console.log(file);
      axios.get(`download/attachment/${file.id}`, { responseType: 'blob' })
          .then(response => {
            this.downloadFile(response, file)
          }).catch(err => {
        console.log(err)
      })
    },
    
    downloadFile(response, file, nameKey="original_name"){
      // const blob = new Blob([response.data], {type : response.headers['content-type']});
      // const url = URL.createObjectURL(blob);
      // const a = document.createElement('a');
      // a.style.display = 'none';
      // a.href = url;
      // a.download = file[nameKey];
      // console.log('sjlnfgvlsnzdkfm', file[nameKey])
      // document.body.appendChild(a);
      // a.click();
      // window.URL.revokeObjectURL(url);
      const blob = new Blob([response.data], { type: response.headers['content-type'] });
          const url = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = file[nameKey];
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);  
    },
    handleChange(file) {
      if (file.hasOwnProperty('response')) {
        const fileType = file.raw.type === '' ? file.raw.name.split('.').pop() : file.raw.type;

        this.documents.forEach((doc, index) => {
          if (parseInt(doc.id) === parseInt(file.response.id)) {
            let size = 0;
            if (doc.size_unit === "KB") {
              size = doc.max_size * 1024;
            } else if (doc.size_unit === "MB") {
              size = doc.max_size * 1024 * 1024;
            } else if (doc.size_unit === "GB") {
              size = doc.max_size * 1024 * 1024 * 1024;
            }
            // console.log('file.raw.size', file.raw.size, size, file.raw.name)
            if (file.raw.size > size) {
              Notification.error(`File size is too large.`);
              return;
            }

            if (doc.formats.length > 0) {
              let isValid = false;
              doc.formats.forEach(format => {
                if (this.commonFileType[format]) {
                  let selectedTag = this.commonFileType[format].find(t => t === fileType)
                  if (selectedTag) {
                    isValid = true;
                  }
                } else if (fileType === format) {
                  isValid = true;
                }
              });
              if (!isValid) {
                Notification.error(`Requested file type is different from uploaded file.`);
                return;
              }
            }
            this.callToFileUploadApi(file);
          }
        })
      }


    },

    callToFileUploadApi(file) {
      this.loading = true;

      let config = {
        headers: {
          'Accept': 'application/json',
          "Authorization": `${this.token}`,
          "content-type": "multipart/form-data"
        }
      }

      let formData = new FormData();
      formData.append('uuid', file.response.uuid);
      formData.append('project_id', this.project.id);
      formData.append('parent_id', file.response.id);
      formData.append('title', file.response.title);
      formData.append('max_size', file.response.max_size);
      formData.append('size_unit', file.response.size_unit);
      formData.append('position', 0);
      formData.append('file', file.raw);

      axios.post('/projects/document-store', formData, config)
          .then((res) => {
            this.getProject();
            if (res.data.success === false && res.data.message) {
              Notification.error(res.data.message)
            }
          })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => {
            this.loading = false;
          })
    },

    removeFile(document) {
      axios.delete(`/projects/document-remove`, {data: {id: document.submitFileId}})
          .then(response => {
            Notification.success(response.data.message);
            let index = this.documents.indexOf(document);
            this.documents[index].submitFileId = null;
            this.documents[index].submitFileName = null;
            this.documents[index].submitFileStatus = null;
            this.$emit('submitted');
          })
    }
  }


}
</script>

<style scoped>

</style>