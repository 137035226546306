<template>
  <div class="join-project">
    <h3 class="card-title mb-4">Join Project</h3>
    <div class="sidebar-sub-menu-item">
      <div class="row">
        <div class="col-md-6">
          <AddExternalProjectModal />
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import AddExternalProjectModal from "@/components/longleap/AddExternalProject.vue";

export default {
  name: "JoinWithExternalLink",
  components: {AddExternalProjectModal},
  methods: {
    handleClick() {
      this.$emit('openModel')
    }
  },
  beforeMount() {
    this.handleClick()
  }
}
</script>

<style scoped lang="scss">

</style>