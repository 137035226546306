<template>
  <div class="container-fluid p-0">
    <div v-if="$route.query.team" class="page-headermb d-flex align-items-center justify-content-between">
      <h2 class="main-content-title mb-2">Team: {{ $route.query.team }}</h2>
      <div v-if="$route.query.menu == 'projects' && authUser.id === team.team_leader">
        <el-button style="margin-left: 15px"
                   type="success" size="mini" class="mb-4"
                   @click.prevent="$router.push(`/team/${$route.query.id}/edit?id=${$route.query.id}`)">
          Edit Team
        </el-button>
        <el-button style="margin-left: 15px"
                   type="danger" size="mini" class="mb-4"
                   :disabled="deleteButtonLoading"
                   @click.prevent="openDeleteTeamModel = true">
          {{ deleteButtonText }}
        </el-button>
      </div>
    </div>
    <div v-else class="page-headermb-3">
      <h2 class="main-content-title">Personal Projects</h2>
    </div>
    <div class="card">
      <div class="card-body">
        <div v-if="$route.query.menu != 'joinWithExternalLinkDescription'">
          <projects-status-filter
              v-if="$route.query.menu === 'projects'"
              :open="openProjects"
              @toggleMenu="toggleMenu('projects')"
              @handleClick="handleClick"
              :active="activeMenu"/>
          <create-project :open="openCreatePublicProject || openCreatePrivateProject"
                          @toggleMenu="toggleMenu($route.query.menu)"
                          @handleClick="handleClick"
                          :active="activeMenu"/>
          <join-with-external-link
              v-if="$route.query.menu === 'joinWithExternalLink'"
              @openModel="openJoinWithExternalLinkModal = true"/>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <projects-list
                v-if="$route.query.menu === 'projects'"
                :status="activeMenu"></projects-list>


            <primary-info v-if="activeMenu === 'primary_info'"/>

            <upload-project-docs v-if="activeMenu === 'upload_project_docs'"/>

            <project-deadline v-if="activeMenu === 'project_deadline'"/>

            <add-collaborators v-if="activeMenu === 'add_collaborators'"/>

            <list-required-docs v-if="activeMenu === 'list_required_docs'"/>

            <assign-documents v-if="activeMenu === 'assign_documents'"/>

            <add-support-members v-if="activeMenu === 'add_support_members'"/>

            <project-summary v-if="activeMenu === 'project_summary'"/>

            <join-with-external-link-description
                v-if="$route.query.menu === 'joinWithExternalLinkDescription'"
                @projectAdded="$router.push(`/personal-project/dashboard?menu=projects&active=active_projects`)"/>
          </div>
        </div>
      </div>
    </div>

    <delete-team-modal
        :show="openDeleteTeamModel"
        :loading="false"
        :heading="`Deleting Team`"
        :text="`Permanently delete this team?`"
        @close="closeDeleteTeamModel"
        @deleteTeam="deleteTeam"
        @downloadDocs="downloadDocs"
    />
  </div>


</template>

<script>
import JoinWithExternalLink from "@/components/longleap/JoinWithExternalLink.vue";
import {Notification} from "element-ui";

import JoinWithExternalLinkDescription from "@/components/longleap/JoinWithExternalLinkDescription.vue";

// Modal
import CreateProject from "@/components/longleap/CreateProject.vue";
import PrimaryInfo from "@/components/longleap/PrimaryInfo.vue";
import UploadProjectDocs from "@/components/longleap/UploadProjectDocs.vue";
import ProjectDeadline from "@/components/longleap/ProjectDeadline.vue";
import AddCollaborators from "@/components/longleap/AddCollaborators.vue";
import ListRequiredDocs from "@/components/longleap/ListRequiredDocs.vue";
import ProjectSummary from "@/components/longleap/ProjectSummary.vue";
import AssignDocuments from "@/components/longleap/AssignDocuments.vue";
import AddSupportMembers from "@/components/longleap/AddSupportMembers.vue";
import AddExternalProjectModal from "@/components/longleap/AddExternalProject.vue";
import ProjectsStatusFilter from "@/components/longleap/ProjectsStatusFilter.vue";
import ProjectsList from "@/components/longleap/ProjectsList.vue";
import DeleteTeamModal from "@/components/longleap/DeleteTeamModal.vue";
import axios from "axios";
import {mapGetters} from "vuex";

export default {
  name: "Index",
  components: {
    ProjectsList,
    ProjectsStatusFilter,
    AddExternalProjectModal,
    AddSupportMembers,
    AssignDocuments,
    ProjectSummary,
    ListRequiredDocs,
    AddCollaborators,
    ProjectDeadline,
    UploadProjectDocs,
    PrimaryInfo,
    CreateProject, JoinWithExternalLinkDescription,
    JoinWithExternalLink,
    DeleteTeamModal
  },
  data() {
    return {
      openProjects: true,
      openCreatePublicProject: false,
      openCreatePrivateProject: false,
      openJoinWithExternalLink: false,
      openJoinWithExternalLinkModal: false,
      openDeleteTeamModel: false,
      deleteButtonText: 'Delete Team',
      deleteButtonLoading: false,
      activeMenu: "active_projects",
      team: {},
    }
  },
  computed: {
    ...mapGetters('auth', ["authUser"]),
    projectType() {
      return this.$route.query.team ? 'team' : 'personal'
    }
  },
  methods: {
    getTeam() {
      axios.get(`/teams/${this.$route.query.id}`)
          .then(res => {
            this.team = res.data;
            // console.log(this.team, "this.team")
          })
    },
    closeDeleteTeamModel() {
      this.openDeleteTeamModel = false;
    },
    deleteTeam() {
      this.closeDeleteTeamModel();
      this.deleteButtonLoading = true
      this.deleteButtonText = 'Deleting Team'
      axios.post(`/teams/${this.$route.query.id}/delete`)
          .then(() => {
            Notification.success('Team has been Deleted. Please wait for confirmation!');
            window.location.href = "/main-dashboard";
          }).catch((err) => {
        Notification.error(err.response.data.error);
      }).finally(()=>{
        this.deleteButtonLoading = false;
        this.deleteButtonText = 'Delete Team';
      });
    },
    downloadDocs() {
      this.closeDeleteTeamModel();
      Notification.info('Your download will begin soon. Do not reload the page');
      axios
          .get(`download/team-documents-all/${this.$route.query.id}`, {responseType: 'blob'})
          .then(response => {
            const contentType = response.headers['content-type'];
            if (contentType === 'application/json') {
              return response.data.text();
            } else {
              return response;
            }
          })
          .then(response => {
            if (typeof response === 'string') {
              const responseData = JSON.parse(response);
              if (responseData.message) {
                Notification.error(responseData.message)
              }
            } else {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `${ this.$route.query.team }.zip`);
              document.body.appendChild(link);
              link.click();
            }
          })
          .catch(error => {
            console.log(error);
          });
    },
    toggleMenu(menu) {
      if (this.projectType === 'team') {

        if (menu === 'projects') {
          this.$router.push(`/personal-project/dashboard?menu=projects&active=active_projects`);
          window.location.reload();
        }
        if (menu === 'createPublicProject') {
          this.$router.push(`/personal-project/dashboard?menu=createPublicProject&active=primary_info`);
          window.location.reload();
        }
        if (menu === 'createPrivateProject') {
          this.$router.push(`/personal-project/dashboard?menu=createPrivateProject&active=primary_info`);
          window.location.reload();
        }
      } else {
        let teamName = this.$route.query.team;
        let teamId = this.$route.query.id;
        if (menu === 'projects') {
          this.$router.push(`/team/projects?team=${teamName}&id=${teamId}&menu=projects&active=active_projects`);
        }
        if (menu === 'createPublicProject') {
          this.$router.push(`/team/projects?team=${teamName}&id=${teamId}&menu=createPublicProject&active=primary_info`);
        }
        if (menu === 'createPrivateProject') {
          this.$router.push(`/team/projects?team=${teamName}&id=${teamId}&menu=createPrivateProject&active=primary_info`);
        }
      }
    },

    handleClick(event) {
      // console.log(event)
      if (this.projectType === 'team') {
        let teamName = this.$route.query.team;
        let teamId = this.$route.query.id;
        if (this.$route.query.hasOwnProperty("project_id")) {
          if (
              event[1] === "active_projects" ||
              event[1] === "draft_projects" ||
              event[1] === "past_projects" ||
              event[1] === "deleted_projects"
          ) {
            this.$router.push(
                `/team/projects?team=${teamName}&id=${teamId}&menu=${event[0]}&active=${event[1]}`
            );
          } else {
            this.$router.push(
                `/team/projects?team=${teamName}&id=${teamId}&menu=${event[0]}&active=${event[1]}&project_id=${this.$route.query.project_id}&type=edit`
            );
          }
          this.activeMenu = event[1];
        } else {
          this.$router.push(
              `/team/projects?team=${teamName}&id=${teamId}&menu=${event[0]}&active=${event[1]}`
          );
          this.activeMenu = event[1];
        }
      } else {
        if (event[2] === undefined) {
          this.$router.push(`/personal-project/dashboard?menu=${event[0]}&active=${event[1]}`);
        } else {
          this.$router.push(`/personal-project/dashboard?menu=${event[0]}&active=${event[1]}&project_id=${event[2]}&type=edit`);
        }
      }
    },

    checkMenus(menuName) {
      // let projectsMenus = [];
      //
      // if (menuName === 'projects') {
      //     projectsMenus = ['active_projects', 'draft_projects', 'past_projects', 'deleted_projects'];
      // } else if (menuName === 'createPublicProject') {
      //     projectsMenus = [
      //         'primary_info', 'upload_project_docs', 'primary_deadline', 'add_collaborators', 'list_required_docs',
      //         'add_support_members', 'project_summary'
      //     ];
      // } else if (menuName === 'createPrivateProject') {
      //     projectsMenus = [
      //         'primary_info', 'upload_project_docs', 'project_deadline', 'add_collaborators', 'list_required_docs',
      //         'assign_documents', 'add_support_members', 'project_summary'
      //     ];
      // } else if (menuName === 'joinWithExternalLink') {
      //     projectsMenus = ['joinWithExternalLink'];
      // }
      //
      // let activeMenu = this.$route.query.active;
      //
      // if (projectsMenus.includes(activeMenu)) {
      //     this.openMenu(menuName);
      //     this.activeMenu = activeMenu;
      // } else {
      //     this.invalidUrl();
      // }
    },

    invalidUrl() {
      // this.openProjects = true;
      // this.activeMenu = 'active_projects';
      // this.$router.push(`/${this.projectType}/dashboard?menu=projects&active=active_projects`);
      // Notification.error('Invalid URL');
    },

    openMenu(menuName) {
      // switch (menuName) {
      //     case "projects":
      //         this.openProjects = true;
      //         break;
      //     case "createPublicProject":
      //         this.openCreatePublicProject = true;
      //         break;
      //     case "createPrivateProject":
      //         this.openCreatePrivateProject = true;
      //         break;
      //     case "joinWithExternalLink":
      //         this.openJoinWithExternalLink = true;
      //         break;
      // }
    }
  },
  watch: {
    '$route.query': {
      handler(value) {
        if (value.menu === 'projects') {
          this.openProjects = true;
          this.openCreatePublicProject = false;
          this.openCreatePrivateProject = false;
        } else if (value.menu === 'createPublicProject') {
          this.openProjects = false;
          this.openCreatePublicProject = true;
          this.openCreatePrivateProject = false;
        } else if (value.menu === 'createPrivateProject') {
          this.openProjects = false;
          this.openCreatePublicProject = false;
          this.openCreatePrivateProject = true;
        } else if (value.menu === 'joinWithExternalLink') {
          this.openJoinWithExternalLink = true;
        }
        this.activeMenu = value.active;
        if (value.team) {
          this.getTeam();
        }
      },
      immediate: true
    }
  }
}
</script>

<style scoped lang="scss">

</style>