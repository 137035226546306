<template>
    <div class="position-relative">
        <div v-if="firstLoading" class="w-100 h-100 position-absolute bg-black d-flex align-items-center justify-content-center bg-white" style="z-index: 10000;">
            <i style="font-size: 25px" class="el-icon-loading text-black"></i>
        </div>

        <div class="d-flex my-4 align-items-center justify-content-center">
            <h2 class="step-title m-0">Primary Information</h2>
            <el-tag v-if="project.hasOwnProperty('status')" style="margin-left: 15px">{{ project.status }}</el-tag>
        </div>

        <el-form
            ref="form"
            :model="formData"
            :rules="rules"
            class="mt-3 project-form"
            label-position="top"
        >
            <div class="row">
                <div class="col-md-6">
                    <el-form-item label="Project Name" prop="name" class="is-required">
                        <el-input
                            v-model="formData.title"
                            placeholder="Please input project name"
                        />
                    </el-form-item>

                    <el-form-item label="Are you collecting docs for an event?" class="is-required">
                        <el-radio v-model="formData.has_files" :label="1">Yes</el-radio>
                        <el-radio v-model="formData.has_files" :label="0">No</el-radio>
                    </el-form-item>
                </div>
                <div class="col-md-6">
                    <el-form-item label="Project Summary (visible to potential collaborators)" prop="summary" class="is-required">
                        <el-input
                            v-model="formData.project_summary"
                            type="textarea"
                            :rows="5"
                            placeholder="Please input project summary"
                        >
                        </el-input>
                    </el-form-item>
                </div>
            </div>

            <div class="d-flex justify-content-end">
                <el-button
                    class="mt-2"
                    type="success"
                    :loading="loading"
                    @click="handleSubmit('form')">Save & Next
                </el-button>
                <el-button
                    v-if="Object.keys(project).length <= 0 || Object.keys(project).length > 0 && project.status !== 'ACTIVE'"
                    class="mt-2"
                    type="success"
                    :loading="loading"
                    @click="handleSubmit('form', 'draft')">Save as Draft
                </el-button>
            </div>
        </el-form>
    </div>
</template>

<script>
import {Notification} from "element-ui";
import axios from "axios";
import ProjectService from "@/services/project.service";

export default {
    name: "PrimaryInfo",
    data() {
        return {
            formData: {
                title: null,
                project_summary: null,
                has_files: 1,
                is_draft: 0,
                project_type: '',
                team_id: this.$route.query.id
            },
            rules: {
                title: [
                    {required: true, message: "Please input project name"},
                    {min: 3, max: 50, message: "Project name must be 3 to 50 characters"},
                ],
                project_summary: [
                    {required: true, message: "Please input project summary"},
                    {min: 3, max: 500, message: "Project summary must be 3 to 500 characters"},
                ],
                has_files: [
                    {required: true, message: "Please select whether you are collecting docs for an event"},
                ],
                is_draft: [
                    {required: true, message: "Please select whether you are saving as draft"},
                ],
            },
            loading: false,
            project: {},
            firstLoading: false
        }
    },
    mounted() {
        if (this.$route.query.hasOwnProperty('project_id') && this.$route.query.type==='edit') {
            this.getProject(this.$route.query.project_id);
        } else {
          if (this.$route.query.team) {
            this.$router.push(`/team/projects?team=${this.$route.query.team}&id=${this.$route.query.id}&menu=${this.$route.query.menu}&active=primary_info`)
          } else {
            this.$router.push(`/personal-project/dashboard?menu=${this.$route.query.menu}&active=primary_info`);
          }
        }
    },
    methods: {
        getProject(id) {
            this.firstLoading = true;
            ProjectService.getProject(id)
                .then(res => {
                    this.project = res.data;
                    this.formData.title = res.data.title;
                    this.formData.project_summary = res.data.project_summary;
                })
                .catch((error) => {
                    // this.$router.push(`/personal-project/dashboard?menu=${this.$route.query.menu}&active=primary_info`);
                    if (error.response.data.errors) {
                        Notification.error(Object.values(error.response.data.errors)[0][0]);
                    }
                    else {
                        Notification.error(error.response.data.message);
                    }
                })
                .finally(() => {
                    this.firstLoading = false;
                })
        },

        handleSubmit(formName, type='save') {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.loading = true;
                    this.formData.project_type = this.$route.query.menu === 'createPrivateProject' ? 'PRIVATE' : 'PUBLIC';
                    if (this.$route.query.hasOwnProperty('project_id')) {
                        this.updateApiCall(type);
                    } else {
                        this.createApiCall(type);
                    }
                } else {
                    Notification.warning('Please input all required fields');
                    return false;
                }
            });
        },

        createApiCall(type) {
          axios.post('/projects/create', this.formData)
                .then(response => {
                    if(response.data.success === false && response.data.message){
                        this.loading = false;
                        return Notification.error(response.data.message)
                    }
                    if (this.$route.query.team) {
                      if (type === 'save') {
                        this.$router.push(`/team/projects?team=${this.$route.query.team}&id=${this.$route.query.id}&menu=${this.$route.query.menu}&active=upload_project_docs&project_id=${response.data.project.id}`);
                      } else {
                        this.$router.push(`/team/projects?team=${this.$route.query.team}&id=${this.$route.query.id}&menu=projects&active=draft_projects`);
                      }
                    } else {
                      if (type === 'save') {
                        this.$router.push(`/personal-project/dashboard?menu=${this.$route.query.menu}&active=upload_project_docs&project_id=${response.data.project.id}`);
                      } else {
                        this.$router.push('/personal-project/dashboard?menu=projects&active=draft_projects');
                      }
                    }
                })
                .catch((error) => {
                  console.log(error)
                    if (error.response.data.errors) {
                        Notification.error(Object.values(error.response.data.errors)[0][0]);
                    }
                    else {
                        Notification.error(error.response.data.message);
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        updateApiCall(type) {
            let data = this.project;
            data.title = this.formData.title;
            if (this.$route.query.id) {
              data.team_id = this.$route.query.id;
            }
            data.project_summary = this.formData.project_summary;

            axios.put(`/projects/update/${this.project.id}`, data)
                .then(response => {
                  if (this.$route.query.team) {
                    if (type === 'save') {
                      this.$router.push(`/team/projects?team=${this.$route.query.team}&id=${this.$route.query.id}&menu=${this.$route.query.menu}&active=upload_project_docs&project_id=${response.data.project.id}`);
                    } else {
                      this.$router.push(`/team/projects?team=${this.$route.query.team}&id=${this.$route.query.id}&menu=projects&active=draft_projects`);
                    }
                  } else {
                    if (type === 'save') {
                      this.$router.push(`/personal-project/dashboard?menu=${this.$route.query.menu}&active=upload_project_docs&project_id=${response.data.project.id}`);
                    } else {
                      this.$router.push('/personal-project/dashboard?menu=projects&active=draft_projects');
                    }
                  }
                })
                .catch((error) => {
                  console.log(error, 'error')
                    if (error.response.data.errors) {
                        Notification.error(Object.values(error.response.data.errors)[0][0]);
                    }
                    else {
                        Notification.error(error.response.data.message);
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
}
</script>

<style scoped>

</style>