<template>
    <div class="position-relative">
        <div v-if="firstLoading" class="w-100 h-100 position-absolute bg-black d-flex align-items-center justify-content-center bg-white" style="z-index: 10000;">
            <i style="font-size: 25px" class="el-icon-loading text-black"></i>
        </div>

        <div class="d-flex my-4 align-items-center justify-content-center">
            <h2 class="step-title m-0">Assign Documents (Required)</h2>
            <el-tag v-if="project.hasOwnProperty('status')" style="margin-left: 15px">{{ project.status }}</el-tag>
        </div>

        <el-form
            ref="form"
            :model="formData"
            :rules="rules"
            class="mt-3 project-form"
            label-position="top"
        >
            <table class="table">
                <tr>
                    <th>Document Name</th>
                    <th>Assign to Collaborators</th>
                </tr>
                <tr
                    v-for="(doc, index) in formData.assignDocuments"
                    :key="doc.id"
                >
                    <td>
                        <div class="link-primary">{{ doc.name }}</div>
                    </td>
                    <td>
                        <el-form-item prop="reminder_lw_first">
                            <el-select
                                style="width: 100%;"
                                v-model="formData.assignDocuments[index].users"
                                multiple
                                default-first-option
                                placeholder="Please select">
                                <el-option
                                    v-for="user in users"
                                    :key="user.id"
                                    :label="user.first_name + ' ' + user.last_name"
                                    :value="user.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </td>
                </tr>
            </table>

            <div class="d-flex justify-content-end">
                <el-button
                    class="mt-2"
                    type="success"
                    :loading="loading"
                    @click="handleSubmit()">Save & Next
                </el-button>
                <el-button
                    v-if="project.hasOwnProperty('status') && project.status !== 'ACTIVE'"
                    class="mt-2"
                    type="success"
                    :loading="loading"
                    @click="handleSubmit('draft')">Save as Draft
                </el-button>
            </div>
        </el-form>
    </div>
</template>

<script>
import axios from "axios";
import {Notification} from "element-ui";
import ProjectService from "@/services/project.service";

export default {
    name: "AssignDocuments",
    data() {
        return {
            formData: {
                assignDocuments: [],
            },
            rules: {},
            loading: false,
            users: [],
            project: {},
            firstLoading: false
        }
    },
    mounted() {
        if (this.$route.query.hasOwnProperty('project_id')) {
            this.getProject(this.$route.query.project_id);
        } else {
            Notification.error('Invalid Project ID');
            if (this.$route.query.team) {
              this.$router.push(`/team/projects?team=${this.$route.query.team}&id=${this.$route.query.id}&menu=${this.$route.query.menu}&active=primary_info`);
            } else {
              this.$router.push(`/personal-project/dashboard?menu=${this.$route.query.menu}&active=primary_info`);
            }
        }
    },
    methods: {
        getProject(id) {
            this.firstLoading = true;
            ProjectService.getProject(id)
                .then(res => {
                    this.project = res.data;
                    if (res.data.hasOwnProperty('documents') && res.data.documents.length > 0) {
                        this.project.documents.forEach(doc => {
                            this.formData.assignDocuments.push({
                                id: doc.id,
                                name: doc.title,
                                users: doc.collaborators.map(user => user.id),
                                position: doc.position,
                            });
                        });
                    }
                    if (res.data.hasOwnProperty('collaborators') && res.data.collaborators.length > 0) {
                        this.users = this.project.collaborators;
                    }
                })
                .catch((error) => {
                    if (error.response.data.errors) {
                        Notification.error(Object.values(error.response.data.errors)[0][0]);
                    }
                    else {
                        Notification.error(error.response.data.message);
                    }
                })
                .finally(() => {
                    this.firstLoading = false;
                })
        },

        handleSubmit(type = 'save') {
          const hasAtLeastOneUser = this.formData.assignDocuments.every(doc => doc.users.length > 0);
          if (! hasAtLeastOneUser) {
            Notification.error('Assign at least one collaborator to each document.');
            return;
          }
          this.loading = true;
            axios.post('/projects/assign-document-file', this.formData)
                .then(() => {
                  if (this.$route.query.team) {
                    if (type === 'save') {
                      this.$router.push(`/team/projects?team=${this.$route.query.team}&id=${this.$route.query.id}&menu=${this.$route.query.menu}&active=add_support_members&project_id=${this.$route.query.project_id}`);
                    } else {
                      this.$router.push(`/team/projects?team=${this.$route.query.team}&id=${this.$route.query.id}&menu=projects&active=draft_projects`);
                    }
                  } else {
                    if (type === 'save') {
                      this.$router.push(`/personal-project/dashboard?menu=${this.$route.query.menu}&active=add_support_members&project_id=${this.project.id}`);
                    } else {
                      this.$router.push('/personal-project/dashboard?menu=projects&active=draft_projects');
                    }
                  }
                })
                .catch(error => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
}
</script>

<style scoped>

</style>