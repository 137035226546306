<template>
    <div class="position-relative">
        <div v-if="firstLoading" class="w-100 h-100 position-absolute bg-black d-flex align-items-center justify-content-center bg-white" style="z-index: 10000;">
            <i style="font-size: 25px" class="el-icon-loading text-black"></i>
        </div>

        <div class="d-flex my-4 align-items-center justify-content-center">
            <h2 class="step-title m-0">Upload Project Documents (Optional)</h2>
            <el-tag v-if="project.hasOwnProperty('status')" style="margin-left: 15px">{{ project.status }}</el-tag>
        </div>

        <el-form
            ref="form"
            :model="formData"
            :rules="rules"
            class="mt-3 project-form"
            label-position="top"
        >
            <div class="row">
                <div class="col-md-12">
                    <el-form-item
                        label="Upload Project Related Files (visible to potential collaborators)"
                        prop="email">
                        <el-upload
                            class="upload-demo"
                            drag
                            :headers="{'Authorization': token}"
                            :action="`${uploadUrl}`"
                            :data="{'project_id': project.id}"
                            :on-success="handleUploadSuccess"
                            :on-remove="handleRemove"
                            :on-error="handleUploadFailed"
                            :file-list="fileList"
                            multiple>
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
                        </el-upload>
                    </el-form-item>
                </div>
            </div>
            <div class="d-flex justify-content-end">
                <el-button
                    class="mt-2"
                    type="success"
                    :loading="loading"
                    @click="handleSubmit()">Save & Next
                </el-button>
                <el-button
                    v-if="project.hasOwnProperty('status') && project.status !== 'ACTIVE'"
                    class="mt-2"
                    type="success"
                    :loading="loading"
                    @click="handleSubmit('draft')">Save as Draft
                </el-button>
            </div>
        </el-form>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {Notification} from "element-ui";
import axios from "axios";
import ProjectService from "@/services/project.service";

export default {
    name: "UploadProjectDocs",
    data() {
        return {
            formData: {},
            rules: {},
            loading: false,
            fileList: [],
            uploadUrl: `${process.env.VUE_APP_API_BASE_URL}/projects/attached-files`,
            project: {},
            firstLoading: false
        }
    },
    computed: {
        ...mapGetters("auth", ["token"]),
    },
    mounted() {
        if (this.$route.query.hasOwnProperty('project_id')) {
            this.getProject(this.$route.query.project_id);
        } else {
            Notification.error('Invalid Project ID');
            if (this.$route.query.team) {
              this.$router.push(`/team/projects?team=${this.$route.query.team}&id=${this.$route.query.id}&menu=${this.$route.query.menu}&active=primary_info`);
            } else {
              this.$router.push(`/personal-project/dashboard?menu=${this.$route.query.menu}&active=primary_info`);
            }
        }
    },
    methods: {
        getProject(id) {
            this.firstLoading = true;
            ProjectService.getProject(id)
                .then(res => {
                    console.log(res.data);
                    this.project = res.data;
                    res.data.attachments.forEach(attachment => {
                        this.fileList.push({
                            name: attachment.original_name,
                            id: attachment.id
                        })
                    });
                })
                .catch((error) => {
                    if (error.response.data.errors) {
                        Notification.error(Object.values(error.response.data.errors)[0][0]);
                    }
                    else {
                        Notification.error(error.response.data.message);
                    }
                })
                .finally(() => {
                    this.firstLoading = false;
                })
        },

        handleSubmit(type='save') {
          if (this.$route.query.team) {
            if (type === 'save') {
              this.$router.push(`/team/projects?team=${this.$route.query.team}&id=${this.$route.query.id}&menu=${this.$route.query.menu}&active=project_deadline&project_id=${this.$route.query.project_id}`);
            } else {
              this.$router.push(`/team/projects?team=${this.$route.query.team}&id=${this.$route.query.id}&menu=projects&active=draft_projects`);
            }
          } else {
            if (type === 'save') {
              this.$router.push(`/personal-project/dashboard?menu=${this.$route.query.menu}&active=project_deadline&project_id=${this.project.id}`);
            } else {
              this.$router.push('/personal-project/dashboard?menu=projects&active=draft_projects');
            }
          }
        },

      handleUploadFailed(error, file, fileList) {
        let response = JSON.parse(error.message);
        console.log(response);  
        Notification.error(response.message);
      },

        handleUploadSuccess(response) {
        console.log(response);
          this.fileList = []
          this.getProject(this.$route.query.project_id)
          Notification.success('File Uploaded');
        },

        handleRemove(file) {
            axios.delete('/projects/attached-files', {data: {id: file.id}})
                .then(() => {
                    this.fileList = this.fileList.filter(item => item.id !== file.id);
                })
        }
    }
}
</script>

<style scoped>

</style>