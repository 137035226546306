<template>
    <div v-if="project.id">
        <h3 class="card-title mb-4">Project: {{ project.title }}</h3>
        <p class="mb-2">
            <strong>Project Owner:</strong> {{
                project.project_owner.first_name + ' ' + project.project_owner.last_name
            }}
        </p>
        <p class="mb-2"><strong>Document Submission Deadline:</strong> {{ project.end_date_first }} ({{project.end_date_first_timezone}})</p>
        <p class="mb-1"><strong>Project Summary</strong></p>
        <p class="mb-3"> {{ project.project_summary }}</p>
        <p class="mb-1"><strong>Document needed from Collaborators</strong></p>
        <div class="table-responsive">
            <table class="table custom-table border mb-4">
                <tr>
                    <th>Document Name</th>
                    <th>Max Size</th>
                    <th>Format</th>
                    <th>Template</th>
                </tr>
                <tr v-for="(document) in project.documents" :key="document.id">
                    <td>{{ document.title }}</td>
                    <td> {{ document.max_size }} {{ document.size_unit }}</td>
                    <td>
                        <el-select
                            class="ex_field"
                            v-model="document.formats.split(',')"
                            placeholder="Select"
                            multiple
                            disabled
                        >
                            <el-option
                                v-for="(item, index) in fileTypes"
                                :key="index"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </td>
                    <td>
                        <div v-if="document.template === null">No Template</div>
                        <div v-else>
                          <el-button
                              type="text"
                              @click.prevent="download(document)"
                          >
                            {{ document.original_name }}
                          </el-button>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
        <div class="d-flex justify-content-end">
            <el-button type="success" :loading="adding" @click="addTheProject">Add the Project</el-button>
        </div>
    </div>
</template>

<script>
import {Select, Notification} from "element-ui";
import axios from "axios";

export default {
    name: "JoinWithExternalLinkDescription",
  components: {
      ElSelect: Select,
  },
    data() {
        return {
          adding: false,
            pid: "",
            project: {},
            size: ["KB", "MB", "GB"],
          fileTypes: [
            {
              value: "image/jpeg",
              label: "JPEG"
            },
            {
              value: "image/png",
              label: "PNG"
            },
            {
              value: "application/pdf",
              label: "PDF"
            },
            {
              value: "application/doc",
              label: "MS Word"
            },
            {
              value: "application/ms-doc",
              label: "MS Word (.docx)"
            },
            {
              value: "application/msword",
              label: "MS Word"
            },
            {
              value: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              label: "MS Word (OpenXML)"
            },
            {
              value: "application/vnd.ms-excel",
              label: "MS Excel"
            },
            {
              value: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              label: "MS Excel (OpenXML)"
            },
            {
              value: "application/vnd.ms-powerpoint",
              label: "MS PowerPoint"
            },
            {
              value: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
              label: "MS PowerPoint (OpenXML)"
            },
            {
              value: "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
              label: "MS PowerPoint (OpenXML)"
            }
          ],
        };
    },
    methods: {
        getProjectInfo() {
            axios
                .get(`/projects/${this.pid}/project-details`)
                .then((res) => {
                    this.project = res.data.data;
                    this.project.documents.forEach(doc => {
                      if (!doc.formats) {
                        doc.formats = ""
                      }
                    })
                })
                .catch((error) => {
                    Notification.error(error.response.data.message);
                });
        },
        addTheProject() {
          this.adding = true;
            axios
                .post(`/projects/${this.project.id}/add-project`, {})
                .then((res) => {
                    //  this.$router.replace({ query: {} })
                    this.$emit('projectAdded');
                })
                .catch((error) => {
                    Notification.error(error.response.data.message);
                })
                .finally(() => {
                  this.adding = false;
                });
        },
        backToProjectList() {
            this.$router.push({name: 'PersonalProjectDashboard'})
        },
      download(file) {
        axios.get(`download/file/${file.id}`, { responseType: 'blob' })
            .then(response => {
              this.downloadFile(response, file)
            }).catch(err => {
          console.log(err)
        })
      },
      downloadFile(response, file){
        const blob = new Blob([response.data], {type: response.headers['content-type']});
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        const filename = `${file.title}.${this.extractStringAfterLastSlash(file.formats)}`;
        a.download = file.original_name;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      },
      extractStringAfterLastSlash(inputString) {
        const parts = inputString.split('/');
        return parts[parts.length - 1];
      }
    },
    mounted() {
        this.pid = this.$route.query.pid;
        if (this.pid) {
            this.getProjectInfo();
        }
    },
};
</script>

<style scoped></style>
