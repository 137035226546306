<template>
  <el-dialog
      :visible.sync="show"
      :before-close="handleClose"
      width="350px"
      class="project-modal"
  >
    <div class="text-center">
      <p>Thanks for Starting Your New Public Project</p>
      <el-button
          type="success"
          @click="handleClose">OK
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "InformationModal",
  props: {
    show: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: "Thanks for starting your new project."
    },
    project: {
      type: Object
    }
  },
  data() {
    return {
      formData: {},
      rules: {}
    }
  },
  methods: {
    handleClose() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>

</style>