<template>
  <div class="sidebar-sub-menu-item">
    <div v-if="open"
         class="sidebar-sub-menu-item">
      <div class="sidebar-sub-menu-open">
        <!-- <div @click.prevent="toggleMenu" style="padding: 10px; cursor: pointer">
            <svg
                xmlns="http://www.w3.org/2000/svg" width="16" height="21"
                viewBox="0 0 16 21">
                <g id="lock-open" transform="translate(-4 -1)">
                    <rect id="Rectangle_2" data-name="Rectangle 2" width="14" height="10" rx="2"
                          transform="translate(5 11)" fill="none" stroke="#000"
                          stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <circle id="Ellipse_13" data-name="Ellipse 13" cx="1" cy="1" r="1"
                            transform="translate(11 15)" fill="none" stroke="#000"
                            stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <path id="Path_3" data-name="Path 3" d="M8,11V6a4,4,0,0,1,8,0" fill="none"
                          stroke="#000" stroke-linecap="round" stroke-linejoin="round"
                          stroke-width="2"/>
                </g>
            </svg>
            <span style="margin-left: 10px">Create a Public Project</span>
        </div>
        <hr style="margin: 0"> -->

        <div
            class="sidebar-button projects-item-count public-project">
          <el-tooltip class="item"
                      effect="dark"
                      content="Primary Information"
                      placement="top">
            <div @click.prevent="handleClick('primary_info')"
                 class="sub_btn"
                 :class="active === 'primary_info' ? 'active' : ''">
              1
            </div>
          </el-tooltip>
          <el-tooltip class="item"
                      :class="{ 'disabledJump': !$route.query.project_id }"
                      effect="dark"
                      content="Upload Project Docs"
                      placement="top">
            <div @click.prevent="handleClick('upload_project_docs')"
                 class="sub_btn"
                 :class="active === 'upload_project_docs' ? 'active' : ''">
              2
            </div>
          </el-tooltip>
          <el-tooltip class="item"
                      :class="{ 'disabledJump': !$route.query.project_id }"
                      effect="dark"
                      content="Project Deadline"
                      placement="top">
            <div @click.prevent="handleClick('project_deadline')"
                 class="sub_btn"
                 :class="active === 'project_deadline' ? 'active' : ''">
              3
            </div>
          </el-tooltip>
          <el-tooltip class="item"
                      :class="{ 'disabledJump': !$route.query.project_id }"
                      effect="dark"
                      content="Add Collaborators"
                      placement="top">
            <div @click.prevent="handleClick('add_collaborators')"
                 class="sub_btn"
                 :class="active === 'add_collaborators' ? 'active' : ''">
              4
            </div>
          </el-tooltip>
          <el-tooltip class="item"
                      :class="{ 'disabledJump': !$route.query.project_id }"
                      effect="dark"
                      content="List Required Docs"
                      placement="top">
            <div @click.prevent="handleClick('list_required_docs')"
                 class="sub_btn"
                 :class="active === 'list_required_docs' ? 'active' : ''">
              5
            </div>
          </el-tooltip>
          <el-tooltip class="item"
                      v-if="isPrivate"
                      :class="{ 'disabledJump': !$route.query.project_id }"
                      effect="dark"
                      content="Assign Documents"
                      placement="top">
            <div @click.prevent="handleClick('assign_documents')"
                 class="sub_btn"
                 :class="active === 'assign_documents' && $route.query.menu === 'createPrivateProject' ? 'active' : ''">
              6
            </div>
          </el-tooltip>
          <el-tooltip class="item"
                      :class="{ 'disabledJump': !$route.query.project_id }"
                      effect="dark"
                      content="Add Support Members"
                      placement="top">
            <div @click.prevent="handleClick('add_support_members')"
                 class="sub_btn"
                 :class="active === 'add_support_members' ? 'active' : ''">
              {{ isPrivate ? '7' : '6' }}
            </div>
          </el-tooltip>
          <el-tooltip class="item"
                      :class="{ 'disabledJump': !$route.query.project_id }"
                      effect="dark"
                      content="Project Summary"
                      placement="top">
            <div @click.prevent="handleClick('project_summary')"
                 class="sub_btn"
                 :class="active === 'project_summary' ? 'active' : ''">
              {{ isPrivate ? '8' : '7' }}
            </div>
          </el-tooltip>
        </div>
      </div>
    </div>

<!--    <div @click.prevent="toggleMenu" v-else>-->
<!--      <div>-->
<!--        <svg xmlns="http://www.w3.org/2000/svg"-->
<!--             width="16" height="21"-->
<!--             viewBox="0 0 16 21">-->
<!--          <g id="lock-open"-->
<!--             transform="translate(-4 -1)">-->
<!--            <rect id="Rectangle_2"-->
<!--                  data-name="Rectangle 2"-->
<!--                  width="14" height="10"-->
<!--                  rx="2"-->
<!--                  transform="translate(5 11)"-->
<!--                  fill="none"-->
<!--                  stroke="#000"-->
<!--                  stroke-linecap="round"-->
<!--                  stroke-linejoin="round"-->
<!--                  stroke-width="2" />-->
<!--            <circle id="Ellipse_13"-->
<!--                    data-name="Ellipse 13"-->
<!--                    cx="1" cy="1" r="1"-->
<!--                    transform="translate(11 15)"-->
<!--                    fill="none"-->
<!--                    stroke="#000"-->
<!--                    stroke-linecap="round"-->
<!--                    stroke-linejoin="round"-->
<!--                    stroke-width="2" />-->
<!--            <path id="Path_3"-->
<!--                  data-name="Path 3"-->
<!--                  d="M8,11V6a4,4,0,0,1,8,0"-->
<!--                  fill="none"-->
<!--                  stroke="#000"-->
<!--                  stroke-linecap="round"-->
<!--                  stroke-linejoin="round"-->
<!--                  stroke-width="2" />-->
<!--          </g>-->
<!--        </svg>-->
<!--        <span-->
<!--            style="margin-left: 10px">Create-->
<!--                    a Public Project</span>-->
<!--      </div>-->
<!--      <svg width="14" height="8"-->
<!--           viewBox="0 0 14 8" fill="none"-->
<!--           xmlns="http://www.w3.org/2000/svg">-->
<!--        <path d="M13 1L7 7L1 1"-->
<!--              stroke="#092C4C"-->
<!--              stroke-width="1.5"-->
<!--              stroke-linecap="round"-->
<!--              stroke-linejoin="round" />-->
<!--      </svg>-->
<!--    </div>-->
  </div>
</template>

<script>
export default {
  name: "CreateProject",
  // data() {
  //   return {
  //     stepCount: 1
  //   }
  // },
  props: {
    open: '',
    active: '',
  },
  computed:{
    isPrivate(){
      return this.$route.query.menu === 'createPrivateProject';
    }
  },
  methods: {
    toggleMenu() {
      this.$emit("toggleMenu");
    },
    handleClick(active) {
      this.$emit("handleClick", [this.$route.query.menu, active, this.$route.query.project_id]);
    }
  }
}
</script>

<style scoped lang="scss">
.disabledJump:active {
  pointer-events: none;
  opacity: 0.6;
}
</style>