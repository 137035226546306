<template>
    <div class="position-relative">
        <div v-if="firstLoading"
             class="w-100 h-100 position-absolute bg-black d-flex align-items-center justify-content-center bg-white"
             style="z-index: 10000;">
            <i style="font-size: 25px"
               class="el-icon-loading text-black"></i>
        </div>

        <div
                class="d-flex my-4 align-items-center justify-content-center">
            <h2 class="step-title m-0">List Documents (Required)</h2>
            <el-tag
                    v-if="project.hasOwnProperty('status')"
                    style="margin-left: 15px">{{
                project.status
                }}
            </el-tag>
        </div>

        <div class="row">
            <div class="col-12 mb-2 text-center">Total number
                of unique documents needed from
                collaborators
            </div>
            <div class="col-md-4 offset-md-4">
                <el-input-number
                        class="w-100 mb-3"
                        v-model="rowNumber"
                        :min="1"></el-input-number>
            </div>
            <div class="col-md-4">
                <el-button @click.prevent="addRow"
                           type="success" plain>Add Rows
                </el-button>
            </div>
        </div>

        <el-form ref="form" :model="formData"
                 :rules="rules"
                 class="mt-3 project-form"
                 label-position="top">
            <div v-for="(document, index) in formData.documents"
                 :key="index"
                 class="px-2 border rounded mb-1">
                <div
                        class="row position-relative">
                    <div
                            class="col-md-12 right-padding-row">
                        <div class="row">
                            <div
                                    class="col-md-6 col-xl-3">
                                <el-form-item
                                        label="Document Name"
                                        prop=""
                                        :error="document.titleError">
                                    <el-input
                                            v-model="document.title"/>
                                </el-form-item>
                            </div>

                            <div
                                    class="col-md-6 col-xl-3">
                                <el-form-item
                                        label="Max file size"
                                        prop=""
                                        :error="document.maxSizeError">
                                    <div
                                            class="d-flex">
                                        <el-input
                                                type="number"
                                                class="px-1"
                                                min="0"
                                                oninput="this.value = Math.abs(this.value)"
                                                v-model="document.max_size"/>
                                        <el-select
                                                v-model="document.size_unit"
                                                placeholder="Select"
                                                class="w-75 px-1">
                                            <el-option
                                                    v-for="item in size"
                                                    :key="item"
                                                    :label="item"
                                                    :value="item">
                                            </el-option>
                                        </el-select>
                                    </div>
                                </el-form-item>
                            </div>

                            <div
                                    class="col-md-6 col-xl-3">
                                <el-form-item
                                        label="File Type Limit"
                                        prop=""
                                        :error="document.formatsError">
                                    <el-select
                                            style="width: 100%"
                                            v-model="document.formats"
                                            placeholder="Select">
                                        <el-option
                                                v-for="(item, index) in fileTypes"
                                                :key="index"
                                                :label="item.label"
                                                :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </div>

                            <div
                                    class="col-md-6 col-xl-3">
                                <el-form-item
                                        label="Upload Template"
                                        prop="">
                                    <el-upload
                                            v-if="!!!document.fileName"
                                            class="upload-demo w-100"
                                            :headers="{ 'Authorization': token }"
                                            :action="uploadUrl"
                                            :data="document"
                                            :show-file-list="false"
                                            :on-change="handleChange" >
                                        <el-button
                                                type="default"
                                                class="w-100"
                                                :disabled="document.formats.length === 0 || document.title.length === 0 || document.max_size === ''">
                                            Upload
                                            <i
                                                    class="el-icon-upload"></i>
                                        </el-button>
                                    </el-upload>
                                    <el-button
                                            v-if="!!document.fileName"
                                            @click.prevent="removeFile(document)"
                                            type="danger"
                                            class="w-100">Remove
                                        File
                                    </el-button>
                                    <span
                                            v-if="!!document.fileName"
                                            class="text-sm d-block">
                                        {{
                                        document.fileName
                                        }}
                                    </span>
                                </el-form-item>
                            </div>
                        </div>
                    </div>
                    <div
                            class="row-action-div d-flex align-items-center justify-content-center">
                        <div class="d-flex">
                            <div class="me-2 cursor-pointer"
                                 @click="documentsMove(index, 'up')"
                                 v-if="formData.documents.length === (index + 1) && formData.documents.length > 1">
                                <img style="width: 22px; height: 22px"
                                     src="@/assets/img/up-arrow.png"
                                     alt=""/>
                            </div>
                            <div class="me-2 cursor-pointer"
                                 @click="documentsMove(index, 'down')"
                                 v-if="formData.documents.length !== (index + 1) && formData.documents.length > 1">
                                <img style="width: 22px; height: 22px"
                                     src="@/assets/img/down-arrow.png"
                                     alt=""/>
                            </div>

                            <div @click.prevent="removeRow(document, index)"
                                 style="cursor: pointer">
                                <svg width="20"
                                     height="20"
                                     viewBox="0 0 20 20"
                                     fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                            d="M5 2V0H15V2H20V4H18V19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H3C2.73478 20 2.48043 19.8946 2.29289 19.7071C2.10536 19.5196 2 19.2652 2 19V4H0V2H5ZM4 4V18H16V4H4ZM7 7H9V15H7V7ZM11 7H13V15H11V7Z"
                                            fill="#00B050"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                    class="d-flex justify-content-end">
                <el-button class="mt-2"
                           type="success"
                           :disabled="loading"
                           :loading="loading"
                           @click="handleSubmit()">Save &
                    Next
                </el-button>
                <el-button
                        v-if="project.hasOwnProperty('status') && project.status !== 'ACTIVE'"
                        class="mt-2" type="success"
                        :disabled="loading"
                        :loading="loading"
                        @click="handleSubmit('draft')">Save
                    as Draft
                </el-button>
            </div>
        </el-form>
    </div>
</template>

<script>
import {Select, Option, Notification} from "element-ui";
import {mapGetters} from "vuex";
import axios from "axios";
import ProjectService from "@/services/project.service";

export default {
    name: "ListRequiredDocs",
    components: {
        ElSelect: Select,
        ElOption: Option
    },
    computed: {
        ...mapGetters("auth", ["token"]),
    },
    data() {
        return {
            formData: {
                documents: [
                    // {
                    //     id: null,
                    //     uuid: this.uuidv4(),
                    //     project_id: this.$route.query.project_id,
                    //     template: "",
                    //     formats: [],
                    //     title: "",
                    //     max_size: "",
                    //     size_unit: "MB",
                    //     position: 0,
                    //     file: null,
                    //     fileName: null,
                    //     titleError: "",
                    //     maxSizeError: "",
                    //     formatsError: "",
                    // }
                ],
            },
            rules: {},
            loading: false,
            isProcessingCount: 0,
            firstLoading: false,
            uploadUrl: `${process.env.VUE_APP_API_BASE_URL}/projects/document-files-temp`,

            rowNumber: 1,

            size: ["MB", "KB", "GB"],
            commonFileType: {
                "application/doc": ["application/doc", "application/ms-doc", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"],
                "application/vnd.ms-excel": ["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"],
                "application/vnd.ms-powerpoint": ["application/vnd.ms-powerpoint", "application/vnd.openxmlformats-officedocument.presentationml.presentation", "application/vnd.openxmlformats-officedocument.presentationml.slideshow"]

            },
            fileTypes: [
                {
                    value: "image/jpeg",
                    label: "JPEG"
                },
                {
                    value: "image/png",
                    label: "PNG"
                },
                {
                    value: "application/pdf",
                    label: "PDF"
                },
                {
                    value: "application/doc",
                    label: "MS Word"
                },
                // {
                //   value: "application/ms-doc",
                //   label: "MS Word (.docx)"
                // },
                // {
                //   value: "application/msword",
                //   label: "MS Word"
                // },
                // {
                //   value: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                //   label: "MS Word (OpenXML)"
                // },
                {
                    value: "application/vnd.ms-excel",
                    label: "MS Excel"
                },
                // {
                //   value: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                //   label: "MS Excel (OpenXML)"
                // },
                {
                    value: "application/vnd.ms-powerpoint",
                    label: "MS PowerPoint"
                },
                // {
                //   value: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
                //   label: "MS PowerPoint (OpenXML)"
                // },
                // {
                //   value: "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
                //   label: "MS PowerPoint (OpenXML)"
                // }
            ],

            project: {},
        }
    },
    mounted() {
        if (this.$route.query.hasOwnProperty('project_id')) {
            this.getProject(this.$route.query.project_id);
        } else {
            Notification.error('Invalid Project ID');
            if (this.$route.query.team) {
              this.$router.push(`/team/projects?team=${this.$route.query.team}&id=${this.$route.query.id}&menu=${this.$route.query.menu}&active=primary_info`);
            } else {
              this.$router.push(`/personal-project/dashboard?menu=${this.$route.query.menu}&active=primary_info`);
            }
        }
    },
    methods: {
        handleChange(file) {
            if (file.hasOwnProperty('response')) {
                this.formData.documents.forEach((doc, index) => {
                    if (doc.uuid === file.response.uuid) {
                        this.formData.documents[index].file = file.raw;
                        this.formData.documents[index].fileName = file.raw.name;
                        // added only for rerender component so that image name would be shown
                        this.rowNumber++;
                        this.rowNumber--;
                    }
                })
            }
        },

        documentsMove(index, direction) {
            let temp = this.formData.documents[index];
            this.formData.documents.splice(index, 1);
            if (direction === 'up') {
                this.formData.documents.splice(index - 1, 0, temp);
            } else {
                this.formData.documents.splice(index + 1, 0, temp);
            }
        },

        getProject(id) {
            this.firstLoading = true;
            ProjectService.getProject(id)
                .then(res => {
                    this.project = res.data;
                    let length = res.data.documents.length;
                    this.rowNumber = length;
                    if (length > 0) {
                        this.formData.documents = [];
                        res.data.documents.forEach((doc, index) => {
                            this.formData.documents.push({
                                id: doc.id,
                                uuid: doc.uuid,
                                project_id: this.$route.query.project_id,
                                template: doc.template,
                                formats: (doc.formats) ? doc.formats : '',
                                title: doc.title,
                                max_size: doc.max_size,
                                size_unit: doc.size_unit,
                                position: index+1,
                                file: doc.file,
                                fileName: doc.original_name,
                                titleError: "",
                                maxSizeError: "",
                                formatsError: "",
                            });
                        })
                    }
                })
                .catch((error) => {
                    if (error.response.data.errors) {
                        Notification.error(Object.values(error.response.data.errors)[0][0]);
                    } else {
                        Notification.error(error.response.data.message);
                    }
                })
                .finally(() => {
                    this.firstLoading = false;
                })
        },

        handleSubmit(type = 'save') {
            this.loading = true;
            let config = {
                headers: {
                    'Accept': 'application/json',
                    "Authorization": `${this.token}`,
                    "content-type": "multipart/form-data"
                }
            }

            let foundError = false;
            let formData = new FormData();

            this.formData.documents.forEach((doc, index) => {
                if (doc.title === "") {
                    this.formData.documents[index].titleError = "Title is required";
                    foundError = true;
                }
                if (doc.max_size === "") {
                    this.formData.documents[index].maxSizeError = "Max Size is required";
                    foundError = true;
                }
                if (doc.formats.length === 0) {
                    this.formData.documents[index].formatsError = "Title is required";
                    foundError = true;
                }
                if (foundError) {
                    this.loading = false;
                    return;
                }
              formData.append(`docs[${index}][data]`, JSON.stringify(doc));
              formData.append(`docs[${index}][file]`, doc.file ? doc.file : '');
            })
          if(this.$route.query.id){
            formData.append(`team_id`, this.$route.query.id);
          }

          axios.post('projects/document-files', formData, config)
              .then(res => {
                console.log('this.isProcessingCount', this.isProcessingCount)
                  let response = res.data;
                  if (response.success === false && response.message) {
                    this.loading = false;
                    this.isProcessingCount--;
                    return Notification.error(response.message)
                  } else {
                    if (this.$route.query.team) {
                      if(this.$route.query.menu.includes('Private')){
                        this.$router.push(`/team/projects?team=${this.$route.query.team}&id=${this.$route.query.id}&menu=${this.$route.query.menu}&active=assign_documents&project_id=${this.$route.query.project_id}`);
                      }else{
                        this.$router.push(`/team/projects?team=${this.$route.query.team}&id=${this.$route.query.id}&menu=${this.$route.query.menu}&active=add_support_members&project_id=${this.$route.query.project_id}`);
                      }
                    } else {
                      if(this.$route.query.menu.includes('Private')) {
                        this.$router.push(`/personal-project/dashboard?menu=${this.$route.query.menu}&active=assign_documents&project_id=${this.project.id}`);
                      }else{
                        this.$router.push(`/personal-project/dashboard?menu=${this.$route.query.menu}&active=add_support_members&project_id=${this.project.id}`);
                      }
                    }
                  }
              })
              .catch((error) => {
                if (error.response.data.errors) {
                  Notification.error(Object.values(error.response.data.errors)[0][0]);
                } else {
                  Notification.error(error.response.data.message);
                }
              })
              .finally(() => {
                this.loading = false
              })
        },

        addRow() {
            let docCount = this.formData.documents.length;
            console.log(docCount);

            if (this.rowNumber < docCount) {
                Notification.info(`You already added ${docCount} rows, If want to reduce the number of rows then you've to delete the row manually`);
            } else {
                let count = parseInt(this.rowNumber) - parseInt(docCount);
                for (let i = 0; i < count; i++) {
                    this.formData.documents.push({
                        id: null,
                        uuid: this.uuidv4(),
                        project_id: this.$route.query.project_id,
                        template: "",
                        formats: "",
                        title: "",
                        max_size: "",
                        size_unit: "MB",
                        position: 0,
                        file: null,
                        titleError: "",
                        maxSizeError: "",
                        formatsError: "",
                    });
                }
            }
        },

        removeRow(document, index) {
            if (document.id === null && document.fileName !== null) {
                this.formData.documents.splice(index, 1);
            } else {
                axios.delete(`projects/document-files`, {data: {id: document.id}})
                    .then(res => {
                        this.formData.documents.splice(index, 1);
                        this.rowNumber--;
                        Notification.success(res.data.message);
                    })
                    .catch((error) => {
                        if (error.response.data.errors) {
                            Notification.error(Object.values(error.response.data.errors)[0][0]);
                        } else {
                            Notification.error(error.response.data.message);
                        }
                    })
            }
        },

        removeFile(document) {
            if (document.id) {
                axios.delete(`projects/document-files`, {data: {id: document.id, only_file: true}})
                    .then(response => {
                        let index = this.formData.documents.indexOf(document);
                        this.formData.documents[index].file = null;
                        this.formData.documents[index].fileName = null;
                        this.rowNumber++;
                        this.rowNumber--;
                    })
            } else {
                let index = this.formData.documents.indexOf(document);
                this.formData.documents[index].file = null;
                this.formData.documents[index].fileName = null;
                this.rowNumber++;
                this.rowNumber--;
            }
        },

        uuidv4() {
            return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
                (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
            );
        }

    }
}
</script>

<style scoped lang="scss">

</style>